import { useState, useEffect } from "react";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { Button, Grid, Theme } from "@mui/material";
import BasicForm from "./BasicForm";
import ContractForm from "./ContractForm";
import OffsetForm from "./OffsetForm";
import { useNotifications } from "../../../../../components/Notification";
import PageSection from "../../../../../components/PageSection";
import { UnitType, DataEditItem, TableBasicInfo } from "../../../../../types";
import { makeStyles } from "@mui/styles";
import MultipleActivity, { TABLE_ROOM_QUERY } from "./MultipleActivity";
import { SAVE_ACTIVITY_EDITS_MUTATION } from "..";
import { detectChanges as detectPriceTypeChanges } from "../utils/price-type-edit-items";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: theme.spacing(2),
  },
}));

// TODO - Export also gql fragment
type Activity = {
  id?: string;
  name?: string;
  courseMenu?: string;
  venueId?: string;
  questionsByVenue?: string[];
  offset?: {
    minutes?: number;
    hours?: number;
  };
  coursePhotos?: {
    activityId: string;
    id: string;
    src: string;
    photoIndex: number;
  }[];
  priceTypes?: {
    id: string;
    name?: string;
    amount?: number;
    unitType?: UnitType;
    contractedPrice?: number;
    currencyCode?: string;
    minAttendees?: number;
    maxAttendees?: number;
    active?: boolean;
  }[];
  seatOptions?: {
    id?: string;
    title?: string;
    details?: string;
    minAttendees?: number;
    maxAttendees?: number;
    active?: boolean;
    photos: { src: string }[];
    price?: number;
    tableId?: string;
    isTableSelect?: boolean;
    durationTime?: number;
  }[];
  contract?: {
    commissionRate?: number;
  };
};

type ActivityFormProps = {
  planId: string;
  refetch: () => void;
};

export default function EditActivityForm(props: ActivityFormProps) {
  const { showNotification } = useNotifications();
  const classes = useStyles();
  const [getTableRooms, { data: tableRooms }] = useLazyQuery(TABLE_ROOM_QUERY);

  const [activity, setActivity] = useState<Activity>();

  const [addNewActivity] = useMutation(ADD_NEW_ACTIVITY_MUTATION);
  const [saveActivityEdits] = useMutation(SAVE_ACTIVITY_EDITS_MUTATION);

  const handleChange = (value: Activity) => {
    setActivity({
      ...activity,
      ...value,
    });
  };

  useEffect(() => {
    if (activity && activity?.venueId) {
      getTableRooms({ variables: { venueId: activity?.venueId } });
    }
  }, [activity]);

  return (
    <Grid container spacing={1}>
      <Grid item lg={6}>
        <div className={classes.box}>
          <BasicForm
            activity={activity}
            onChange={handleChange}
            planId={props.planId}
          />
        </div>
      </Grid>
      <Grid item lg={6}>
        <div className={classes.box}>
          <OffsetForm activity={activity} onChange={handleChange} />
        </div>
        <div className={classes.box}>
          <ContractForm activity={activity} onChange={handleChange} />
        </div>
      </Grid>
      <Grid item lg={12}>
        <MultipleActivity
          activity={[activity as Activity]}
          onChange={handleChange}
          planId={props.planId}
          venueId={activity?.venueId}
        />
      </Grid>
      <PageSection lg={12}>
        <Button
          variant="outlined"
          onClick={() => {
            if (
              !activity?.coursePhotos ||
              activity?.coursePhotos?.length == 0
            ) {
              return showNotification({
                message: `${activity?.name || "Activity"
                  } course photos cannot be left blank!`,
                severity: "error",
              });
            }

            const activeSeatOption =
              activity.seatOptions?.filter((seat) => seat.active === true) ||
              [];
            if (activeSeatOption.length === 0) {
              showNotification({
                message: `${activity.name || "Activity"
                  } requires at least 1 active seat option.`,
                severity: "error",
              });
              return;
            }

            const dataEdits: DataEditItem[] = detectPriceTypeChanges(activity);
            const rooms = tableRooms?.tableRoomsByVenue?.records ?? [];

            addNewActivity({
              variables: {
                input: {
                  planId: props.planId,
                  activity: {
                    ...activity,
                    seatOptions: activity?.seatOptions
                      ? activity.seatOptions.map((s) => {
                        const table = rooms?.find(
                          (table: TableBasicInfo) => table.id === s.tableId
                        );
                        return {
                          ...s,
                          price: s.price ? s.price : 0,
                          tableId: s.isTableSelect ? s.tableId : null,
                          durationTime: s.isTableSelect
                            ? s.durationTime
                            : null,
                          isTableSelect: s.isTableSelect
                            ? s.isTableSelect
                            : false,
                          ...(s.isTableSelect ? { title: table?.name } : {}),
                          ...(s.isTableSelect
                            ? { details: table?.description ?? table?.name }
                            : {}),
                          ...(s.isTableSelect
                            ? { maxAttendees: table?.maxAttendees }
                            : {}),
                          ...(s.isTableSelect
                            ? { minAttendees: table?.minAttendees }
                            : {}),
                          ...(s.isTableSelect
                            ? {
                              prices: table?.tablePrices.map(
                                (price: any) => ({
                                  key: price.id,
                                  price: price.price,
                                  unitType: "ROOM",
                                  weekdays: price.weekdays,
                                  holidays: price.holidays,
                                })
                              ),
                            }
                            : {}),
                          ...(s.isTableSelect
                            ? {
                              photos: table?.tablePhotos.map(
                                (photo: any) => ({
                                  src: photo.photoUrl,
                                })
                              ),
                            }
                            : {}),
                        };
                      })
                      : [],
                    coursePhotos: activity.coursePhotos.map((photo: any) => {
                      return {
                        ...photo,
                        activityId: "",
                      };
                    }),
                  },
                },
              },
            })
              .then(async (data: any) => {
                if (dataEdits.length > 0) {
                  await saveActivityEdits({
                    variables: {
                      input: {
                        editItems: dataEdits,
                        activityId: data?.data?.addNewActivity?.activity?.id,
                      },
                    },
                  });
                }

                showNotification({
                  message: `${activity?.name || "Activity"
                    } saved successfully `,
                  severity: "success",
                });
                props.refetch();
              })
              .catch((err: any) => {
                let message: string = `Could not save form. ${err?.message}`;
                if (err?.networkError)
                  message = `Could not save form. Please make sure to fill all required fields, ${err?.networkError?.result?.errors[0]?.extensions?.code
                    } - ${err?.networkError?.result?.errors[0]?.message?.substring(
                      0,
                      501
                    )}`;
                showNotification({
                  message,
                  severity: "error",
                });
              });
          }}
        >
          Save
        </Button>
      </PageSection>
    </Grid>
  );
}

const ADD_NEW_ACTIVITY_MUTATION = gql`
  mutation AddNewActivityMutation($input: AddNewActivityInput!) {
    addNewActivity(input: $input) {
      planId
      activity {
        id
      }
    }
  }
`;
