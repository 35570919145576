import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import Button from "../Button2";
import DataTable from "../DataTable";
import { VenueTag } from "../../types";

export default function VenueTagsTable(props: {
  tags: VenueTag[];
  onAdd: (tag: VenueTag) => void;
}) {
  const pageSize = 5;
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const tagIds = useMemo(() => new Set(props.tags.map((tag) => tag.id)), [
    props.tags,
  ]);
  const { data, loading, error, fetchMore } = useQuery(VENUE_TAGS_QUERY, {
    variables: {
      offset: page * pageSize,
      limit: pageSize,
      search: search,
    },
  });
  if (error) return <>ERROR: {error.message}</>;

  const rows = data ? data.venueTags.records : null;

  return (
    <DataTable
      loading={loading}
      columns={[
        { name: "name", render: renderName },
        { name: "", render: renderAdd },
      ]}
      rows={rows}
      pagination={{
        totalRows: data ? data.venueTags.total : null,
        pageSize: pageSize,
        currentPage: page,
        onChangePage: (_, newPage) => {
          setPage(newPage);
          fetchMore({
            variables: {
              offset: newPage * pageSize,
              limit: pageSize,
            },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        },
      }}
      onSearch={(e) => setSearch(e.target.value)}
      searchValue={search}
    />
  );

  function renderName(row: { name: string }) {
    return <div style={{ textAlign: "center" }}>{row.name}</div>;
  }

  function renderAdd(row: { id: number; name: string }) {
    return (
      <div style={{ textAlign: "right" }}>
        <Button
          disabled={tagIds.has(row.id)}
          size="small"
          onClick={() =>
            props.onAdd({
              id: row.id,
              name: row.name
            })
          }
        >
          Add
        </Button>
      </div>
    );
  }
}

const VENUE_TAGS_QUERY = gql`
  query VenueTagsQuery($offset: Int!, $limit: Int!, $search: String) {
    venueTags(offset: $offset, limit: $limit, search: $search) {
      total
      records {
        id
        name
        createdAt
      }
    }
  }
`;
