import { useEffect } from "react";
import TextField from "../../../../components/TextField";
import { BookingSystem } from "../../../../types";
import SelectRow, { SelectRowProps } from "./SelectRow";

const ToretaSelect = ({
    toretaStoreId,
    setToretaStoreId,
   ...selectRowProps
 }: EbicaSelectProps) => {
  useEffect(() => {
    return () => {
      setToretaStoreId(null);
    };
  }, [setToretaStoreId]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <SelectRow {...selectRowProps} />
        <div>
          <TextField
            required
            label="Toreta Store ID"
            value={toretaStoreId}
            onChange={({
               target: { value },
             }: React.ChangeEvent<HTMLInputElement>) => {
              setToretaStoreId(value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ToretaSelect;

type EbicaSelectProps = {
  toretaStoreId: string | null;
  setToretaStoreId: (toretaStoreId: string | null) => void;
} & SelectRowProps<BookingSystem>;
