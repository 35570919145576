import {
  OperationVariables,
  FetchMoreQueryOptions,
  ApolloQueryResult,
} from "@apollo/client";
import { Stack } from "@mui/system";
import { ParsedQuery } from "query-string";
import { useState } from "react";
import { GetBookingFailLogsInput, GetBookingFailLogsResponse } from "..";
import DataTable from "../../../../components/DataTable";
import DateRangePicker, {
  DateRangePickerProps,
} from "../../../../components/DaterangePicker";
import TagSelect, { TagSelectProps } from "./TagSelect";

export default function BookingsDataTable({
  parsedQueryString,
  loading,
  rows,
  tagFilter,
  createdDateRangeFilter,
  search,
  setSearch,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  pageSize,
  totalRows,
  fetchMore,
}: BookingsDataTableProps) {
  const Headers = {
    id: "ID",
    bookingId: "Booking ID",
    status: "Status",
    message: "Message",
    formattedCreatedAt: "Created",
  } as const;

  const columns = [
    { name: Headers.id, field: "id" },
    { name: Headers.bookingId, field: "bookingId" },
    { name: Headers.status, field: "stringStatus" },
    { name: Headers.message, field: "message" },
    {
      name: Headers.formattedCreatedAt,
      field: "formattedCreatedAt",
      sortable: true,
      sortField: "createdAt",
    },
  ];

  return (
    <div className="bookings-container">
      <DataTable
        style={{ paddingTop: 8 }}
        loading={loading}
        order={{
          field: orderBy ?? "",
          direction: order ?? "ASC",
          setField: setOrderBy,
          setDirection: setOrder,
        }}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        topBarLeadingExtras={
          <Stack direction="row" alignItems="center" spacing={1}>
            <TagSelect {...tagFilter} onChangeCallback={() => setPage(0)} />
            <Stack spacing={1}>
              <DateRangePicker
                {...createdDateRangeFilter}
                fromLabel="created from"
                untilLabel="created until"
              />
            </Stack>
          </Stack>
        }
        pagination={{
          totalRows,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
    </div>
  );
}

type BookingsDataTableProps = {
  tagFilter: Omit<TagSelectProps, "onChangeCallback">;
  createdDateRangeFilter: Pick<
    DateRangePickerProps,
    "from" | "setFrom" | "until" | "setUntil"
  >;
  parsedQueryString: ParsedQuery<string>;
  loading: boolean;
  rows:
  | {
    [key: string]: any;
  }[]
  | null
  | undefined;
  orderBy: string | null;
  setOrderBy: React.Dispatch<React.SetStateAction<string | null>>;
  order: "ASC" | "DESC" | null;
  setOrder: React.Dispatch<React.SetStateAction<"ASC" | "DESC" | null>>;
  search: string | undefined;
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  totalRows: number;
  fetchMore: <
    TFetchData = GetBookingFailLogsResponse,
    TFetchVars extends OperationVariables = GetBookingFailLogsInput
  >(
    fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
      updateQuery?:
      | ((
        previousQueryResult: GetBookingFailLogsResponse,
        options: {
          fetchMoreResult: TFetchData;
          variables: TFetchVars;
        }
      ) => GetBookingFailLogsResponse)
      | undefined;
    }
  ) => Promise<ApolloQueryResult<TFetchData>>;
};
