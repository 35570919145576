import { IconButton, Tooltip } from "@mui/material";
import BaseCard, { BaseCardProps, getCard } from "./BaseCard";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { ApplyEditDialog, DiscardEditDialog } from "./components/VerifyDialog";
import { PartnerPlanEdit, PartnerPlanEditAction, PlanDraft } from "../../../../../types";
import { useNotifications } from "../../../../../components/Notification";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  GET_PLAN_IDS_WITH_EDITS,
  GetPlanIdsWithEditsResponse,
} from "../../../../../components/Menu";

export type EditCardProps = Omit<
  BaseCardProps & { mutable: true; originalData: any },
  "mutable" | "headerContent" | "contentRef" | "originalValue"
> & {
  partnerEdits: PartnerPlanEdit[];
  setPartnerEdits: Dispatch<SetStateAction<PartnerPlanEdit[]>>;
};

const enum ModalType {
  Apply,
  Reject,
}

export default function EditCard({
  originalData,
  mutationProps,
  partnerEdits,
  setPartnerEdits,
  ...props
}: EditCardProps) {
  // This card is used for when the operators ought to be able to update the plan
  const apolloClient = useApolloClient();

  const [currentModal, setCurrentModal] = useState<ModalType | null>(null);
  const { showNotification } = useNotifications();
  const contentRef = useRef<{
    persistChanges: () => Promise<void>;
    getNewValue: () => any;
  }>();

  const fieldName = props.edit.fieldName;
  const newValue = JSON.parse(props.edit.newValue);
  const fieldId = newValue.fieldId;

  const { getOriginalValue } = getCard(fieldName);

  const originalValue = getOriginalValue({
    data: originalData,
    fieldName,
    fieldId,
  });

  const [verifyEdit] = useMutation<
    VerifyPartnerEditMutationResponse,
    VerifyPartnerEditMutationinput
  >(VERIFY_PARTNER_EDIT_MUTATION);

  const [createPlanDraft] = useMutation<
    { success: boolean },
    { input: { planId: string } }
  >(CREATE_PLAN_DRAFT);

  const { data: planData, loading: planLoading, error: planError, refetch } = useQuery<
    getPlanDraftResponse,
    getPlanDraftInput
  >(GET_PLAN_DRAFT, {
    variables: {
      planId: props.edit.planId,
      last: 1
    },
  });

  const [publishPlanMutation] = useMutation<
    PublishPlanResponse,
    PublishPlanInput
  >(PUBLISH_PLAN);
  const handleVerifyEdit = async (action: keyof typeof PartnerPlanEditAction, comment: string) => {
    const localStorageUser = localStorage.getItem("user");
    const user = localStorageUser ? JSON.parse(localStorageUser) : null;
    const mainVenue = originalData.mainVenue;

    verifyEdit({
      variables: {
        input: {
          id: props.edit.id,
          action,
          oldValue: JSON.stringify(originalValue),
          newValue:
            action === "ACCEPTED"
              ? JSON.stringify({
                ...originalValue,
                ...(contentRef.current?.getNewValue() ?? {}),
              })
              : undefined,
          comment: comment,
          userId: user.id,
          venueName: mainVenue?.name,
        },
      },
    });

    const updatedPartnerEdits = partnerEdits.filter(
      (edit) => edit.id !== props.edit.id
    );

    setPartnerEdits(updatedPartnerEdits);
    await createPlanDraft({
      variables: { input: { planId: props.edit.planId } },
    })
    const planDraft = await refetch();

    if (planData) {
      publishPlanMutation({
        variables: {
          input: {
            planDraftId: planDraft?.data?.getPlanDraftsByPlanId[0]?.id,
          },
        },
      })
        .then(() => {
          showNotification({
            message: `Plan was published successfully`,
            severity: "success",
          });
        })
        .catch((reason: Error) => {
          showNotification({
            message: `Unpublish plan failed! ${reason.message}`,
            severity: "error",
          });
        });
    }

    if (updatedPartnerEdits.length === 0) {
      // update sidebar announcement bell cache
      const cachedPlans = (apolloClient.cache.readQuery({
        query: GET_PLAN_IDS_WITH_EDITS,
      }) as GetPlanIdsWithEditsResponse).plansWithPendingEdits.plans;

      apolloClient.cache.writeQuery({
        query: GET_PLAN_IDS_WITH_EDITS,
        data: {
          plansWithPendingEdits: {
            plans: cachedPlans.filter((plan) => plan.id !== props.edit.planId!),
          },
        },
      });
    }
  };

  return (
    <>
      <BaseCard
        contentRef={contentRef}
        mutable={true}
        originalValue={originalValue}
        mutationProps={{ ...mutationProps, fieldName, fieldId }}
        {...props}
        headerContent={
          <div>
            <Tooltip title="Apply changes">
              <IconButton onClick={() => setCurrentModal(ModalType.Apply)}>
                <CheckCircleOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject changes">
              <IconButton onClick={() => setCurrentModal(ModalType.Reject)}>
                <HighlightOffOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
      />

      <ApplyEditDialog
        open={currentModal === ModalType.Apply}
        handleClose={() => setCurrentModal(null)}
        onConfirm={async (comment: string) => {
          try {
            await contentRef.current?.persistChanges();
            handleVerifyEdit(PartnerPlanEditAction.ACCEPTED, comment);
            showNotification({
              message: `Changes successfully saved`,
              severity: "success",
            });
          } catch (error: unknown) {
            showNotification({
              message: `Saving changes failed: ${error}`,
              severity: "error",
            });
          }
        }}
      />
      <DiscardEditDialog
        open={currentModal === ModalType.Reject}
        handleClose={() => setCurrentModal(null)}
        onConfirm={(comment: string) => handleVerifyEdit(PartnerPlanEditAction.REJECTED, comment)}
      />
    </>
  );
}

const VERIFY_PARTNER_EDIT_MUTATION = gql`
  mutation VerifyPartnerEditMutation($input: VerifyPartnerPlanEditInput!) {
    verifyPartnerPlanEdit(input: $input) {
      success
      error
    }
  }
`;

const CREATE_PLAN_DRAFT = gql`
  mutation CreatePlanDraft($input: CreatePlanDraftInput!) {
    createPlanDraft(input: $input) {
      success
    }
  }
`;

const GET_PLAN_DRAFT = gql`
  query GetPlanDraftQuery(
  $planId: ID!
  $last: Int = 5
  ) {
    getPlanDraftsByPlanId(
    planId: $planId
    last: $last
    ) {
      id
    }
  }
`;

type getPlanDraftInput = {
  planId: string;
  last: number;
};

type getPlanDraftResponse = {
  getPlanDraftsByPlanId: [PlanDraft];
};

const PUBLISH_PLAN = gql`
  mutation PublishPlanMutation($input: PublishPlanInput!) {
    publishPlan(input: $input) {
      publishedAt
    }
  }
`;

type PublishPlanInput = {
  input: {
    planDraftId: string;
  };
};

type PublishPlanResponse = {
  publishedAt: string;
};

type VerifyPartnerEditMutationinput = {
  input: {
    id: string;
    action: keyof typeof PartnerPlanEditAction;
    oldValue: string;
    newValue?: string;
    comment?: string;
    userId: string;
    venueName?: any;
  };
};

type VerifyPartnerEditMutationResponse = {
  success: boolean;
  error: string;
};
