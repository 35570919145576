import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState, useMemo } from "react";
import Button from "../../../../components/Button2";
import DataTable, { Column } from "../../../../components/DataTable";
import { useQuery, gql } from "@apollo/client";
import moment from "moment-timezone";

export default function ListRefundButton(props: { bookingId: string }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        style={{ marginLeft: 10 }}
        color="secondary"
        onClick={() => setDialogOpen(true)}
      >
        Refund history
      </Button>
      <ListRefundDialog
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        bookingId={props.bookingId}
      />
    </>
  );
}

function ListRefundDialog(props: {
  bookingId: string;
  open: boolean;
  closeDialog: () => void;
}) {
  const pageSize = 10;
  const [page, setPage] = useState(0);

  const { loading, data, fetchMore } = useQuery(GET_REFUND_HISTORY, {
    variables: {
      bookingId: props.bookingId,
      offset: page * pageSize,
      limit: pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const rows = useMemo(
    () =>
      data
        ? data.refundHistories.records.map((item: any) => ({
            ...item,
            backofficeUserId: item?.backofficeUserId ?? "なし",
            partnerUserId: item?.partnerUserId ?? "なし",
          }))
        : [],
    [data]
  );
  const totalRows = useMemo(
    () => (data ? data.refundHistories.totalRecords : 0),
    [data]
  );

  const columns = [
    { name: "id", field: "id" },
    { name: "Backoffice User Id", field: "backofficeUserId" },
    { name: "partner User Id", field: "partnerUserId" },
    { name: "Method", field: "method" },
    { name: "Amount", field: "amount" },
    { name: "Status", field: "status" },
    {
      name: "Created At",
      render: renderCreatedAt,
    },
  ];

  return (
    <Dialog maxWidth="xl" open={props.open} onClose={props.closeDialog}>
      <DialogTitle>
        <h3 style={{ textAlign: "center" }}>Refund history</h3>
      </DialogTitle>
      <DialogContent style={{ width: 1400 }}>
        <DataTable
          loading={loading}
          columns={columns}
          rows={rows}
          disableToolbar
          pagination={{
            totalRows: totalRows ?? null,
            pageSize: pageSize,
            currentPage: page,
            onChangePage: (_, newPage) => {
              setPage(newPage);
              fetchMore({
                variables: {
                  bookingId: props.bookingId,
                  offset: newPage * pageSize,
                  limit: pageSize,
                },
                updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
              });
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            props.closeDialog();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function renderCreatedAt(history: { createdAt: Date }) {
  return (
    <>
      {moment(new Date(Number(history.createdAt))).format("YYYY-MM-DD HH:mm")}
    </>
  );
}

const GET_REFUND_HISTORY = gql`
  query RefundHistoriesQuery($offset: Int!, $limit: Int!, $bookingId: ID!) {
    refundHistories(offset: $offset, limit: $limit, bookingId: $bookingId) {
      records {
        id
        status
        amount
        method
        chargeId
        backofficeUserId
        partnerUserId
        createdAt
      }
      totalRecords
    }
  }
`;
