import { Card, CardContent, CardHeader } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import {VenueTag} from "../../../types";
import VenueTagsSelection from "../../../components/VenueTagSelection";

export default function VenusTags(props: VenueTagsProps) {
  const tags = props.venueTags?.value ? props.venueTags?.value : [];

  return (
    <Card>
      <CardHeader title="Tags" />
      <CardContent>
        <VenueTagsSelection venueTags={tags} setTags={props.venueTags.set} />
      </CardContent>
    </Card>
  );
}

interface VenueTagsProps {
  venueTags: {
    value: VenueTag[] | undefined;
    set: Dispatch<SetStateAction<VenueTag[]>>;
  };
}
