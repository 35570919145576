import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { ButtonGroup, Button, Grid, Theme } from "@mui/material";
import BasicForm from "./BasicForm";
import ContractForm from "./ContractForm";
import OffsetForm from "./OffsetForm";
import { useNotifications } from "../../../../../components/Notification";
import PageSection from "../../../../../components/PageSection";
import { UnitType, DataEditItem, TableBasicInfo } from "../../../../../types";
import { makeStyles } from "@mui/styles";
import MultipleActivity, { TABLE_ROOM_QUERY } from "./MultipleActivity";
import { SAVE_ACTIVITY_EDITS_MUTATION } from "..";
import { detectChanges as detectPriceTypeChanges } from "../utils/price-type-edit-items";
import { SeatOption } from "./SeatOptionsForm";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: theme.spacing(2),
  },
}));

const removeEmptyFields = (activity: Activity) => {
  // nested values could be cleaned recursively, but then graphql mutation is sent for each step
  // specifically remove the price of seat option if null
  activity.seatOptions?.forEach((so) => so.price ?? delete so["price"]);
  return Object.fromEntries(
    Object.entries(activity).filter(([_, v]) => v !== null && v !== "")
  ) as Activity;
};

const omitTypename = (fieldToOmit: string) => (key: string, value: any) =>
  key === fieldToOmit ? undefined : value;

const deepOmit = (
  fieldToOmit: string,
  data: Record<string, unknown>
): Record<string, unknown> => {
  return JSON.parse(JSON.stringify(data), omitTypename(fieldToOmit));
};

// TODO - Export also gql fragment
export type Activity = {
  id?: string;
  name?: string;
  venueId?: string;
  courseMenu?: string;
  coursePhotos?: {
    activityId: string;
    id: string;
    src: string;
    photoIndex: number;
  }[];
  questionsByVenue?: string[];
  offset?: {
    minutes?: number;
    hours?: number;
  };
  priceTypes?: {
    id: string;
    name?: string;
    unitType?: UnitType;
    amount?: number;
    contractedPrice?: number;
    currencyCode?: string;
    minAttendees?: number;
    maxAttendees?: number;
    active?: boolean;
  }[];
  seatOptions?: {
    id?: string;
    title?: string;
    details?: string;
    minAttendees?: number;
    maxAttendees?: number;
    active?: boolean;
    isTableSelect?: boolean;
    tableId?: string;
    durationTime?: number;
    photos: { src: string }[];
    price?: number;
    table?: TableBasicInfo;
  }[];
  priceOptions?: {
    id?: string;
    amount?: number;
    index?: number;
    contractedAmount?: number;
    startDate?: Date;
    endDate?: Date;
  }[];
  contract?: {
    commissionRate?: number;
  };
  editHistories?: {
    id: string;
    activityId: string;
    fieldName: string;
    newValue: string;
    oldValue: string;
    editStatus: string;
  }[];
  venue?: {
    id: string;
    toretaStoreId?: string;
  };
};

type ActivityFormProps = {
  planId: string;
  activity: Activity;
  refetch: () => void;
  activities?: Activity[];
};

export default function EditActivityForm(props: ActivityFormProps) {
  const { showNotification } = useNotifications();
  const classes = useStyles();

  const cleanActivity: Activity = deepOmit(
    "__typename",
    props.activity
  ) as Activity;

  const { id } = useParams<{ id: string }>();
  const [active, setActive] = useState(0);
  const [activity, setActivity] = useState<Activity>(cleanActivity);
  const [saveActivity] = useMutation(SAVE_ACTIVITY_MUTATION);
  const [addNewActivity] = useMutation(ADD_NEW_ACTIVITY_MUTATION);
  const [saveActivityEdits] = useMutation(SAVE_ACTIVITY_EDITS_MUTATION);
  const [getTableRooms, { data: tableRooms }] = useLazyQuery(TABLE_ROOM_QUERY);

  const handleChange = (value: Activity) => {
    setActivity({
      ...activity,
      ...value,
    });
  };

  const checkValidateSeatOptionTable = (
    seatOptions: SeatOption[] | undefined
  ) => {
    if (!seatOptions) return false;
    return seatOptions.find((s) => !s.tableId && s.isTableSelect);
  };

  const checkValidateSeatOptionToreta = (
    seatOptions: SeatOption[] | undefined
  ) => {
    if (!seatOptions || !activity?.venue?.toretaStoreId) return false;
    return seatOptions.find((s) => !s.title);
  };

  const checkValidateSeatOption = (seatOptions: SeatOption[] | undefined) => {
    if (!seatOptions || activity?.venue?.toretaStoreId) return false;
    return seatOptions.find((s) => s.photos.length == 0);
  };

  useEffect(() => {
    if (activity && activity?.venue?.id) {
      getTableRooms({ variables: { venueId: activity?.venue?.id } });
    }
  }, [activity]);

  const handleSave = () => {
    const activeSeatOption =
      activity.seatOptions?.filter((seat) => seat.active === true) || [];
    if (activeSeatOption.length === 0) {
      showNotification({
        message: `${activity.name || "Activity"
          } requires at least 1 active seat option.`,
        severity: "error",
      });
      return;
    }

    if (checkValidateSeatOptionTable(activity.seatOptions)) {
      showNotification({
        message: `${activity.name || "Activity"
          } Please select table in seat option.`,
        severity: "error",
      });
      return;
    }

    if (checkValidateSeatOptionToreta(activity.seatOptions)) {
      showNotification({
        message: `${activity.name || "Activity"
          } Please select toreta seat option.`,
        severity: "error",
      });
      return;
    }

    if (checkValidateSeatOption(activity.seatOptions)) {
      showNotification({
        message: `${activity.name || "Activity"
          } Please select photos seat option.`,
        severity: "error",
      });
      return;
    }

    const originalActivity = props.activities?.find(
      (item) => item.id === activity.id
    );
    const dataEdits: DataEditItem[] = detectPriceTypeChanges(
      activity,
      originalActivity
    );

    delete activity.editHistories;
    const rooms = tableRooms?.tableRoomsByVenue?.records ?? [];

    const dataSubmit: any = {
      ...activity,
      seatOptions: activity?.seatOptions
        ? activity.seatOptions.map((s) => {
          const table = rooms?.find(
            (table: TableBasicInfo) => table.id === s.tableId
          );
          return {
            ...s,
            price: s.price ? s.price : 0,
            tableId: s.isTableSelect ? s.tableId : null,
            durationTime: s.isTableSelect ? s.durationTime : null,
            isTableSelect: s.isTableSelect ? s.isTableSelect : false,
            ...(s.isTableSelect ? { title: table?.name } : {}),
            ...(s.isTableSelect
              ? { details: table?.description ?? table?.name }
              : {}),
            ...(s.isTableSelect ? { maxAttendees: table?.maxAttendees } : {}),
            ...(s.isTableSelect ? { minAttendees: table?.minAttendees } : {}),
            ...(s.isTableSelect
              ? {
                prices: table?.tablePrices.map((price: any) => ({
                  key: price.id,
                  price: price.price,
                  unitType: "ROOM",
                  weekdays: price.weekdays,
                  holidays: price.holidays,
                })),
              }
              : {}),
            ...(s.isTableSelect
              ? {
                photos: table?.tablePhotos.map((photo: any) => ({
                  src: photo.photoUrl,
                })),
              }
              : {}),
          };
        })
        : [],
    };

    if (dataSubmit.deleteId) delete dataSubmit.deleteId;
    if (dataSubmit?.venue) {
      delete dataSubmit.venue;
    }
    if (dataSubmit?.id) {
      if (dataSubmit.coursePhotos.length == 0) {
        return showNotification({
          message: `${dataSubmit?.name || "Activity"
            } course photos cannot be left blank!`,
          severity: "error",
        });
      }
      saveActivity({
        variables: {
          input: removeEmptyFields(dataSubmit),
        },
      })
        .then(async () => {
          if (dataEdits.length > 0) {
            await saveActivityEdits({
              variables: {
                input: { editItems: dataEdits, activityId: activity.id },
              },
            });
          }

          showNotification({
            message: `${dataSubmit.name || "Activity"} saved successfully.`,
            severity: "success",
          });
          props.refetch();
        })
        .catch((err) => {
          let message: string = `Could not save form. ${err?.message}`;
          if (err?.networkError)
            message = `Could not save form. Please make sure to fill all required fields, ${err?.networkError?.result?.errors[0]?.extensions?.code
              } - ${err?.networkError?.result?.errors[0]?.message?.substring(
                0,
                501
              )}`;
          showNotification({
            message,
            severity: "error",
          });
        });
    } else {
      if (dataSubmit.coursePhotos.length == 0) {
        return showNotification({
          message: `${dataSubmit?.name || "Activity"
            } course photos cannot be left blank!`,
          severity: "error",
        });
      }
      addNewActivity({
        variables: {
          input: {
            planId: props.planId,
            activity: dataSubmit,
          },
        },
      })
        .then(async (data: any) => {
          if (dataEdits.length > 0) {
            await saveActivityEdits({
              variables: {
                input: {
                  editItems: dataEdits,
                  activityId: data?.data?.addNewActivity?.activity?.id,
                },
              },
            });
          }

          showNotification({
            message: `${dataSubmit?.name || "Activity"} saved successfully `,
            severity: "success",
          });
          setActivity((prev: Activity) => {
            return {
              ...prev,
              id: data?.data?.addNewActivity?.activity?.id,
            };
          });
          props.refetch();
        })
        .catch((err: any) => {
          let message: string = `Could not save form. ${err?.message}`;
          if (err?.networkError)
            message = `Could not save form. Please make sure to fill all required fields, ${err?.networkError?.result?.errors[0]?.extensions?.code
              } - ${err?.networkError?.result?.errors[0]?.message?.substring(
                0,
                501
              )}`;
          showNotification({
            message,
            severity: "error",
          });
        });
    }
  };

  useEffect(() => {
    if (activity.id) {
      setActive(
        (props.activities?.findIndex((a) => a.id === activity.id) as number) ??
        0
      );
    }
  }, [props.activities, activity]);

  return (
    <Grid container spacing={1}>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <div className={classes.box}>
            <BasicForm
              activity={activity}
              onChange={handleChange}
              planId={props.planId}
            />
          </div>
        </Grid>
        <Grid item lg={6}>
          <div className={classes.box}>
            <OffsetForm activity={activity} onChange={handleChange} />
          </div>

          <div className={classes.box}>
            <ContractForm activity={activity} onChange={handleChange} />
          </div>
        </Grid>
      </Grid>
      <MultipleActivity
        activity={props.activities}
        onChange={handleChange}
        planId={props.planId}
        refetch={props.refetch}
        setActive={setActive}
        active={active}
      />
      <PageSection lg={12}>
        <ButtonGroup>
          <Button
            variant="outlined"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </PageSection>
    </Grid>
  );
}

const SAVE_ACTIVITY_MUTATION = gql`
  mutation SaveActivityMutation($input: SaveActivityInput!) {
    saveActivity(input: $input) {
      activity {
        id
        name
      }
    }
  }
`;

const ADD_NEW_ACTIVITY_MUTATION = gql`
  mutation AddNewActivityMutation($input: AddNewActivityInput!) {
    addNewActivity(input: $input) {
      planId
      activity {
        id
      }
    }
  }
`;

const PLAN_ACTIVITIES_QUERY = gql`
  query PlanActivityQuery($id: ID!) {
    plan(id: $id) {
      id
      activities {
        id
        name
        courseMenu
        coursePhotos {
          activityId
          id
          photoIndex
          src
        }
        detailsForVenue
        contract {
          commissionRate
          onSiteCommissionRate
        }
        priceTypes {
          id
          name
          unitType
          amount
          contractedPrice
          currencyCode
          minAttendees
          maxAttendees
          index
          active
        }
        venue {
          id
          name
        }
        offset {
          minutes
          hours
        }
        seatOptions {
          id
          title
          details
          minAttendees
          maxAttendees
          photos {
            src
          }
          active
          price
        }
        questionsByVenue
      }
    }
  }
`;
