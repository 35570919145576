import {
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { VenueTag } from "../../../types";
import {gql, useMutation} from "@apollo/client";
import {useNotifications} from "../../../components/Notification";

type EditVenueTagProps = {
  selectedTag?: VenueTag;
  onSaveCallback: () => void;
  refetch: () => void;
  tagNames: Set<string|unknown>;
};
export default function EditVenueTag({
  selectedTag,
  onSaveCallback,
  refetch,
  tagNames
}: EditVenueTagProps) {
  const [name, setName] = useState(selectedTag?.name ?? "");
  const { showNotification } = useNotifications();

  const saveError = useMemo(() => {
    if (!name) return "Need a name";
    if (
      name === selectedTag?.name
    )
      return "No changes";

    if (name !== selectedTag?.name && tagNames.has(name))
      return "Tag name already exists";
  }, [
    selectedTag?.name,
    name,
    tagNames
  ]);

  const [updateVenueTag] = useMutation<
    { success: boolean },
    { input: { id: number|undefined, name: string } }
  >(UPDATE_VENUE_TAG);

  return (
    <Stack direction="column" gap={1}>
      <TextField
        label="name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        fullWidth
      />
      <Typography
        sx={{ color: "red", minHeight: "1.5em", textAlign: "center" }}
      >
        {saveError}
      </Typography>

      <Button
        variant="contained"
        disabled={!!saveError}
        onClick={() => {
          updateVenueTag({
            variables: { input: { id: selectedTag?.id, name: name } },
          })
            .then(() => {
              if (refetch) {
                refetch();
              }
              onSaveCallback()
              showNotification({
                severity: "success",
                message: `success`,
              });
            })
            .catch((err: any) => {
              showNotification({
                severity: "error",
                message: err.message.substring(0, 1000),
              });
            });
        }}
      >
        Save
      </Button>
    </Stack>
  );
}

const UPDATE_VENUE_TAG = gql`
  mutation UpdateVenueTag($input: VenueTagInput!) {
    updateVenueTag(input: $input) {
      success
    }
  }
`;
