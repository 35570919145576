import { v4 as uuid } from "uuid";
import { useMemo } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "../../../components/TextField";

import styles from "./TableRoomPricesForm.module.css";

import TableRoomAvailabilityForm, {
  SeatOption,
  SeatOptionPriceJson,
} from "./TableRoomAvailabilityForm";

type SeatOptionPricesFormProps = {
  seatOption: SeatOption;
  seatOptionIdx: number;
  onChange: (fieldName: keyof SeatOption, index: number, value: any) => void;
  onDelete?: (key: string) => void;
};

export default function TableRoomPricesForm({
  seatOption,
  seatOptionIdx,
  onChange,
  onDelete,
}: SeatOptionPricesFormProps) {
  function setPriceField<T>(
    fieldName: keyof SeatOptionPriceJson,
    index: number,
    value: T
  ) {
    const prices = [...(seatOption.prices ?? [])];

    prices[index] = {
      ...prices[index],
      [fieldName]: value,
    };

    onChange("prices", seatOptionIdx, prices);
  }

  const addNewPrice = () => {
    const prices = [...(seatOption.prices ?? [])];
    prices.push({
      key: uuid(),
      price: 0,
      unitType: "ROOM",
      weekdays: [],
      holidays: [],
    });

    onChange("prices", seatOptionIdx, prices);
  };

  const removePrice = (priceIdx: number) => {
    const prices = [...(seatOption.prices ?? [])];
    const removePrice = prices.find((_, idx) => idx === priceIdx);

    onChange(
      "prices",
      seatOptionIdx,
      prices.filter((_, idx) => idx !== priceIdx)
    );
    if (removePrice?.key && onDelete) onDelete(removePrice?.key);
  };

  const selectedDays = useMemo(() => {
    return seatOption.prices?.reduce((accumulated, item) => {
      const result = [...accumulated, ...item.weekdays, ...item.holidays];

      return [...new Set(result)];
    }, [] as string[]);
  }, [seatOption]);

  return (
    <Card className={styles.Card}>
      <CardHeader
        title="Prices"
        action={
          <IconButton onClick={addNewPrice}>
            <AddIcon />
          </IconButton>
        }
        className={styles.CardHeader}
      />
      <CardContent>
        <Stack spacing={2}>
          {seatOption.prices?.map((item, idx) => (
            <Card variant="outlined" key={item.key}>
              <CardHeader
                className={styles.CardHeader}
                action={
                  idx !== 0 && (
                    <IconButton onClick={() => removePrice(idx)}>
                      <DeleteIcon />
                    </IconButton>
                  )
                }
              />
              <CardContent className={styles.CardContent}>
                <TableRoomAvailabilityForm
                  price={item}
                  priceIdx={idx}
                  disabledDays={selectedDays}
                  onChange={setPriceField}
                />
                <TextField
                  id={`${seatOption.id}_price_${idx}`}
                  label="Price (optional additional fee)"
                  value={item.price || 0}
                  startAdornment={"¥"}
                  onChange={(e: any) => {
                    setPriceField("price", idx, Number(e.target.value ?? 0));
                  }}
                />
              </CardContent>
            </Card>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
