import { v4 as uuid } from "uuid";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TextField from "../../../../../components/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import React from "react";

export type PriceOption = {
  id?: string;
  amount?: number;
  contractedAmount?: number;
  index?: number;
  startDate?: Date;
  endDate?: Date;
};

type Activity = {
  priceOptions?: PriceOption[];
};

type priceOptionsFormProps = {
  planId: string;
  activity?: Activity;
  onChange: (activity: Activity) => void;
};

type Fn<T> = (value: T) => T;

function isFunction<T>(value: T | Fn<T>): value is Fn<T> {
  return typeof value === "function";
}

export default function priceOptionsForm(props: priceOptionsFormProps) {
  const { activity, onChange } = props;
  const priceOptions = activity?.priceOptions || [];

  function setpriceOptionsField<T>(
    fieldName: keyof PriceOption,
    index: number,
    value: T | Fn<T>
  ) {
    const changeable = [...priceOptions];
    const tobechanged = priceOptions[index];
    if (!tobechanged) return;
    const currentValue = (tobechanged[fieldName] as unknown) as T;
    const newValue = isFunction(value) ? value(currentValue) : value;

    const PriceOption = {
      ...tobechanged,
      [fieldName]: newValue,
    };

    changeable[index] = PriceOption;
    onChange({ priceOptions: changeable });
  }

  const removeSeat = (s: PriceOption) => {
    let seatList = priceOptions.filter((a) => a.id !== s.id);

    onChange({ priceOptions: seatList });
  };
  console.log(priceOptions, "priceOptions")
  return (
    <Card>
      <CardHeader
        title="Price options"
        action={
          <IconButton
            onClick={() => {
              onChange({
                priceOptions: priceOptions.concat({
                  id: uuid(),
                }),
              });
            }}
          >
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        {priceOptions.map((s, index) => (
          <Grid key={index} container spacing={2}>
            <Grid item lg={12} style={{marginBottom: 10}}>
              <Grid item lg={6}>
                <TextField
                  required
                  size="small"
                  label="Amount"
                  startAdornment={"¥"}
                  value={s.amount}
                  defaultValue={0}
                  onChange={(e: any) => {
                    setpriceOptionsField("amount", index, Number(e.target.value));
                  }}
                />
                <TextField
                  size="small"
                  label="Contracted amount"
                  defaultValue={0}
                  startAdornment={"¥"}
                  value={s.contractedAmount}
                  onChange={(e: any) => {
                    setpriceOptionsField("contractedAmount", index, Number(e.target.value));
                  }}
                />
                <TextField
                  size="small"
                  label="Index"
                  value={s.index}
                  onChange={(e: any) => {
                    setpriceOptionsField("index", index, Number(e.target.value));
                  }}
                />
                <TextField
                  required
                  size="small"
                  label="Start Date"
                  type="date"
                  value={s.startDate}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setpriceOptionsField("startDate", index, event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  required
                  size="small"
                  label="End Date"
                  type="date"
                  value={s.endDate}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setpriceOptionsField("endDate", index, event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Button
              style={{
                marginLeft: 10,
              }}
              variant="contained"
              color="secondary"
              onClick={() => removeSeat(s)}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
            <Grid item xs={12}>
              <Divider
                style={{ width: "auto", marginTop: 12, marginBottom: 12 }}
              />
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
}
