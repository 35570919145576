import { useParams } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import Photos from "./Photos";
import Schedule from "./Schedule";
import TableList from "../Edit/TableRoom";
import DataTabs from "../../../components/DataTabs";
import { useState } from "react";

const TabIndices: Record<string, number> = {
  basicInfo: 0,
  photos: 1,
  schedule: 2,
  overview: 3,
};

export default function EditVenue() {
  const { tab } = useParams<{ tab: string }>();
  const tabIndex = TabIndices[tab!] || 0;
  const [disableTableRoom, setDisableTableRoom] = useState<boolean>(false);

  return (
    <DataTabs
      selectedTab={tabIndex}
      tabs={[
        {
          title: "Basic info",
          content: <BasicInfo setDisableTableRoom={setDisableTableRoom} />,
        },
        {
          title: "Photos",
          content: <Photos />,
        },
        {
          title: "Schedule",
          content: <Schedule />,
        },
        {
          title: "Table(Room)",
          content: <TableList />,
          disabled: disableTableRoom,
        },
      ]}
    />
  );
}
