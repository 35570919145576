import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiFillGift } from "react-icons/ai";
import { ImFont } from "react-icons/im";
import { FaStamp } from "react-icons/fa";
import { SiAuth0 } from "react-icons/si";
import { BsPersonLinesFill } from "react-icons/bs";
import { RiImageAddFill } from "react-icons/ri";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ViewListIcon from "@mui/icons-material/ViewList";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import StoreIcon from "@mui/icons-material/Store";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MoneyIcon from "@mui/icons-material/Money";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SavingsIcon from "@mui/icons-material/Savings";
import TagIcon from "@mui/icons-material/Tag";
import useStyles from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import RecyclingIcon from "@mui/icons-material/Recycling";
import Logo from "../Logo";
import { useGlobalContext } from "../../GlobalContext";
import UserMenu from "./UserMenu";
import { gql, useLazyQuery } from "@apollo/client";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AccessPermission } from "../../types";

export const menuItems = ({
  accessPermissions,
}: {
  accessPermissions: AccessPermission[];
}) => {
  const baseItems = [
    { name: "Home", url: "/", icon: <HomeIcon /> },
    {
      name: "Bookings",
      path: "/bookings",
      icon: <ReceiptIcon />,
      subItems: [
        { name: "List", url: "/bookings", icon: <ViewListIcon /> },
        {
          name: "Reviews",
          url: "/bookings/reviews",
          icon: <ChatBubbleIcon />,
        },
        { name: "Fail Logs", url: "/bookings/logs", icon: <ViewListIcon /> },
      ],
    },
    {
      name: "Plans",
      path: "/plans",
      icon: <AssignmentIcon />,
      subItems: [
        {
          name: "List",
          url: "/plans",
          icon: <ViewListIcon />,
        },
        {
          name: "Collections",
          url: "/plans/collections",
          icon: <LibraryBooksIcon />,
        },
        {
          name: "Collection Images",
          url: "/plans/collections/images",
          icon: <RiImageAddFill size="22" />,
        },
        {
          name: "Tags",
          url: "/tags",
          icon: <TagIcon />,
        },
        { name: "Import", url: "/plans/import", icon: <PublishIcon /> },
        {
          name: "Trash",
          url: "/plans?page=1&trashed=true",
          icon: <DeleteIcon />,
          permissionPath: "/plans",
        },
      ],
    },
    {
      name: "Venues",
      path: "/venues",
      icon: <StoreIcon />,
      subItems: [
        { name: "List", url: "/venues", icon: <ViewListIcon /> },
        {
          name: "Reservations",
          url: "/venues/reservations",
          icon: <EventAvailableIcon />,
        },
        { name: "Costs", url: "/venues/costs-overview", icon: <MoneyIcon /> },
        { name: "Import", url: "/venues/import", icon: <PublishIcon /> },
        { name: "Export", url: "/venues/export", icon: <GetAppIcon /> },
        { name: "Tag", url: "/venues/tags", icon: <TagIcon /> },
      ],
    },
    {
      name: "Companies",
      url: "/companies",
      icon: <BusinessIcon />,
    },
    {
      name: "Locations",
      url: "/locations",
      icon: <LocationOnIcon />,
    },
    { name: "Partners", url: "/partners", icon: <EmojiPeopleIcon /> },
    {
      name: "Items",
      path: "/items",
      icon: <AiFillGift size={25} />,
      subItems: [
        { name: "List", url: "/items", icon: <ViewListIcon /> },
        { name: "Fonts", url: "/items/fonts", icon: <ImFont /> },
        { name: "Stamps", url: "/items/stamps", icon: <FaStamp /> },
        { name: "Sales", url: "/items/sales", icon: <SavingsIcon /> },
        { name: "Trash", url: "/items/trash", icon: <DeleteIcon /> },
      ],
    },
    {
      name: "Announcements",
      url: "/announcements",
      icon: <AnnouncementIcon />,
    },
    { name: "Concierges", url: "/concierges", icon: <BsPersonLinesFill /> },
    { name: "Roles", url: "/roles", icon: <SiAuth0 /> },
    { name: "Users", url: "/users", icon: <GroupIcon /> },
  ];

  return baseItems.filter((item) =>
    accessPermissions?.find(
      (route: AccessPermission) =>
        item.url === route.path || item.path === route.path
    )
  );
};

const Menu = () => {
  const { isLoggedIn } = useGlobalContext();
  const classes = useStyles();

  const [
    // we don't use this variable, because we have polling
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _getEdits,
    { data: dataEdits, startPolling: startPollingEdits },
  ] = useLazyQuery<{
    plansWithPendingEdits: { plans: { id: string }[] };
  }>(GET_PLAN_IDS_WITH_EDITS);

  const [
    // we don't use this variable, because we have polling
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _getBookings,
    { data: dataBookings, startPolling: startPollingBookings },
  ] = useLazyQuery<{
    getBookingsWithBuyableItems: { bookings: { id: string }[] };
  }>(GET_BOOKING_IDS_WITH_BUYABLE_ITEMS);

  const notifications = useMemo(
    // the route is a dotted notation of names found in menuItems()
    // e.g. Plans.List takes 'Plans' from the base items, and then 'List' from Plans' subitems
    () =>
      [
        [
          "Plans.List",
          (dataEdits?.plansWithPendingEdits.plans.length ?? 0) > 0,
        ],
        [
          "Bookings.List",
          (dataBookings?.getBookingsWithBuyableItems.bookings.length ?? 0) > 0,
        ],
      ]
        .filter(([_, hasNotifications]) => hasNotifications)
        .map(([route, _]) => route) as string[],
    [dataEdits, dataBookings]
  );

  useEffect(() => {
    // only fetch notification data if logged in
    if (!isLoggedIn) return;
    if (startPollingEdits !== undefined) startPollingEdits(1000 * 60);
    if (startPollingBookings !== undefined) startPollingBookings(1000 * 60);
  }, [isLoggedIn, startPollingEdits, startPollingBookings]);

  if (!isLoggedIn) return null; // Don't show menu if not logged in

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.logo}>
        <Logo iconColor="#80642a" textColor="#ffffff" />
      </div>
      <MenuList notifications={notifications} />
    </Drawer>
  );
};

const MenuList = ({ notifications }: { notifications: string[] }) => {
  const classes = useStyles();
  const location = useLocation();
  const { accessPermissions } = useGlobalContext();

  const menuItemsWithNotifications = notifications.reduce(
    (topLevelItems, notification) => {
      const itemWithNotifications: any = notification
        .split(".")
        .reduce(
          (item: { subItems?: any[] }, subPath) =>
            item?.subItems?.find((item) => item.name === subPath),
          { subItems: topLevelItems }
        );
      if (itemWithNotifications) itemWithNotifications.hasNotifications = true;
      return topLevelItems;
    },
    menuItems({ accessPermissions })
  );

  const openedSubMenu = menuItemsWithNotifications.find((menuItem) =>
    menuItem.path !== undefined
      ? location.pathname.includes(menuItem.path)
      : false
  );
  const [openSubMenu, setOpenSubMenu] = useState(
    openedSubMenu ? openedSubMenu.name : null
  );

  const listItems = [];
  for (const menuItem of menuItemsWithNotifications) {
    if (menuItem.url !== undefined) {
      listItems.push(
        <LinkListItem
          key={menuItem.name}
          name={menuItem.name}
          url={menuItem.url}
          icon={menuItem.icon}
        />
      );
    }
    if (menuItem.subItems !== undefined) {
      listItems.push(
        <FolderListItem
          key={menuItem.name}
          name={menuItem.name}
          path={menuItem.path}
          icon={menuItem.icon}
          subItems={menuItem.subItems.filter((item) => {
            const url = item.permissionPath ? item.permissionPath : item.url;

            return accessPermissions?.find(
              (route: AccessPermission) => url === route.path
            );
          })}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
        />
      );
    }
  }

  return (
    <div className={classes.menuList}>
      <List>{listItems}</List>
      <div className={classes.userMenuDiv}>
        <UserMenu />
      </div>
    </div>
  );
};

const LinkListItem = (props: {
  name: string;
  url: string;
  icon: JSX.Element;
  sub?: boolean;
  hasNotifications?: boolean;
}) => {
  const classes = useStyles();
  const location = useLocation();

  const isActive = () => {
    if (props.name === "Trash") {
      return location.search.includes("trashed=true");
    }
    if (props.name === "List" && props.url === "/plans") {
      return location.pathname === "/plans" && !location.search.includes("trashed=true");
    }
    return location.pathname === props.url;
  };

  return (
    <ListItem
      key={props.name}
      button
      component={Link}
      to={props.url}
      selected={isActive()}
      className={props.sub ? classes.subMenuItem : undefined}
    >
      <ListItemIcon className={classes.listItemIcon}>{props.icon}</ListItemIcon>
      <ListItemText primary={props.name} />
      {props.hasNotifications && (
        <ListItemIcon
          style={{ minWidth: "0" }}
          className={classes.listItemIcon}
        >
          <NotificationsIcon />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

const FolderListItem = (props: {
  name: string;
  path: string;
  icon: JSX.Element;
  subItems: {
    name: string;
    url: string;
    icon: JSX.Element;
    hasNotifications?: boolean;
  }[];
  openSubMenu: string | null;
  setOpenSubMenu: any;
}) => {
  const classes = useStyles();
  const location = useLocation();

  const subItems = props.subItems.map((subItem) => (
    <LinkListItem
      key={subItem.name}
      name={subItem.name}
      url={subItem.url}
      icon={subItem.icon}
      hasNotifications={subItem.hasNotifications}
      sub={true}
    />
  ));

  const subItemsHaveNotifications = props.subItems.some(
    (subItem) => subItem.hasNotifications
  );

  return (
    <>
      <ListItem
        key={props.name}
        button
        selected={location.pathname.includes(props.path)}
        onClick={() =>
          props.openSubMenu === props.name
            ? props.setOpenSubMenu(null)
            : props.setOpenSubMenu(props.name)
        }
      >
        <ListItemIcon className={classes.listItemIcon}>
          {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.name} />
        {subItemsHaveNotifications && (
          <ListItemIcon
            style={{ minWidth: "0", opacity: "50%" }}
            className={classes.listItemIcon}
          >
            <NotificationsIcon />
          </ListItemIcon>
        )}
      </ListItem>
      <Collapse
        in={props.openSubMenu === props.name}
        timeout="auto"
        unmountOnExit
      >
        <List className={classes.subMenuList} component="div">
          {subItems}
        </List>
      </Collapse>
    </>
  );
};

export default Menu;

export const GET_BOOKING_IDS_WITH_BUYABLE_ITEMS = gql`
  query BookingsWithBuyableItemsQuery {
    getBookingsWithBuyableItems {
      bookings {
        id
      }
    }
  }
`;

export type GetBookingIdsWithBuyableItemsResponse = {
  getBookingsWithBuyableItems: {
    bookings: {
      id: string;
    }[];
  };
};

export const GET_PLAN_IDS_WITH_EDITS = gql`
  query EditsQuery {
    plansWithPendingEdits {
      plans {
        id
      }
    }
  }
`;

export type GetPlanIdsWithEditsResponse = {
  plansWithPendingEdits: {
    plans: {
      id: string;
    }[];
  };
};
