import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import React, { useState } from "react";

function VerifyDialog({
  open,
  handleClose,
  onConfirm,
  title,
  text,
}: EditDialogProps) {

  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    if (comment) {
      onConfirm(comment);
      handleClose();
    } else {
      setError(true);
    }
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogContent>
          <TextField
            id="comment"
            required={true}
            error={error}
            helperText={error ? "Comment is required" : ""}
            label="Comment"
            multiline
            name="comment"
            rows={4}
            fullWidth
            placeholder="Comment"
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
            }}
          >
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  onConfirm: (comment: string) => void;
};

type EditDialogProps = DialogProps & {
  text: string;
  title: string;
};

export function DiscardEditDialog(props: DialogProps) {
  const title = "Discard partner edits";
  const text = `Are you sure you finished processing the edit requested by the
  partner?

  If you discard the edit it will disappear from this page.
  `;
  return <VerifyDialog {...props} title={title} text={text} />;
}

export function ApplyEditDialog(props: DialogProps) {
  const title = "Apply partner edits";
  const text = `Are you sure you finished processing the edit requested by the
  partner?

  If you apply the edit the plan will be altered and the edit will disappear from this page.
  `;

  return <VerifyDialog {...props} title={title} text={text} />;
}
