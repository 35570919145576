import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import queryString from "query-string";

import "./index.css";
import BookingsDataTable from "./components/BookingsDataTable";
import { BookingFailLogStatus } from "./components/TagSelect";

function BookingFailLogsList() {
  const navigate = useNavigate();
  const location = useLocation();

  const parsedQueryString = queryString.parse(location.search, {
    arrayFormat: "bracket-separator",
    arrayFormatSeparator: "|",
  });

  const pageSize = 10;
  const [page, setPage] = React.useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = React.useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : undefined
  );

  const [createdFrom, setCreatedFrom] = React.useState<string | null>(
    parsedQueryString.createdFrom as string
  );

  const [createdUntil, setCreatedUntil] = React.useState<string | null>(
    parsedQueryString.createdUntil as string
  );

  const [orderBy, setOrderBy] = React.useState<string | null>(
    parsedQueryString.orderBy as string
  );

  const [order, setOrder] = React.useState<"ASC" | "DESC" | null>(
    parsedQueryString.order as "ASC" | "DESC"
  );

  const [
    status,
    setStatus,
  ] = React.useState<BookingFailLogStatus | null>(null);

  React.useEffect(() => {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }, [page, search, navigate]);

  const { loading, error, data, fetchMore } = useQuery<
    GetBookingFailLogsResponse,
    GetBookingFailLogsInput
  >(GET_BOOKING_FAIL_LOGS, {
    variables: {
      offset: page * pageSize,
      limit: pageSize,
      search: search,
      status: status === null ? undefined : status,
      createdFrom: createdFrom ?? undefined,
      createdUntil: createdUntil ?? undefined,
      orderBy: !!orderBy && !!order ? { column: orderBy, order } : undefined,
    },
  });

  if (error) return <>Error! {error.message}</>;

  const rows = data ? data.bookingFailLogs.records : null;

  return (
    <BookingsDataTable
      parsedQueryString={parsedQueryString}
      loading={loading}
      rows={rows}
      tagFilter={{
        status,
        setStatus,
      }}
      createdDateRangeFilter={{
        from: createdFrom,
        setFrom: setCreatedFrom,
        until: createdUntil,
        setUntil: setCreatedUntil,
      }}
      search={search}
      setSearch={setSearch}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      pageSize={pageSize}
      totalRows={data ? data.bookingFailLogs.total : 0}
      fetchMore={fetchMore}
    />
  );
}

const GET_BOOKING_FAIL_LOGS = gql`
  query GetBookingFailLogs(
    $offset: Int!, 
    $limit: Int!, 
    $search: String,
    $status: Int,
    $createdFrom: Date,
    $createdUntil: Date,
  ) {
    bookingFailLogs(
      offset: $offset, 
      limit: $limit, 
      search: $search,
      status: $status
      createdFrom: $createdFrom
      createdTo: $createdUntil
    ) {
      total
      records {
        id
        bookingId
        stringStatus
        message
        formattedCreatedAt
      }
    }
  }
`;

export type GetBookingFailLogsResponse = {
  bookingFailLogs: {
    records: {
      id: string;
      stringStatus: string;
      message: string,
      formattedCreatedAt: string,
    }[];
    total: number;
  };
};


export type GetBookingFailLogsInput = {
  offset: number;
  limit: number;
  search?: string;
  status?: number;
  createdFrom?: string;
  createdUntil?: string;
  orderBy?: { column: string; order: "ASC" | "DESC" };
};

export default BookingFailLogsList;
