import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import Notification, {
  NotificationData,
} from "../../../../components/Notification";
import { TableBasicInfo } from "../../../../types";

export default function DeleteTableRoomDialog(
  props: DeleteTableRoomDialogProps
) {
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );
  const [deleteTableRoom] = useMutation(DELETE_TABLE_ROOM_MUTATION);

  return (
    <>
      {props.tableRoom && (
        <Dialog open={props.open} onClose={props.onClose}>
          <DialogTitle>Delete Table Room</DialogTitle>

          <DialogContent>
            Are you sure you want to delete table room?
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={deleteTableRoomHandler}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function deleteTableRoomHandler() {
    if (props.tableRoom) {
      const tableRoomName = props.tableRoom.name;

      deleteTableRoom({
        variables: { id: props.tableRoom.id },
        refetchQueries: ["TableRoomQuery"],
      }).then(() => {
        setNotification({
          message: `Table room ${tableRoomName} was deleted`,
          severity: "success",
        });
      });
    }
    props.onClose();
  }
}

const DELETE_TABLE_ROOM_MUTATION = gql`
  mutation DeleteTableMutation($id: ID!) {
    deleteTableRoom(id: $id) {
      success
    }
  }
`;

interface DeleteTableRoomDialogProps {
  open: boolean;
  onClose: () => void;
  tableRoom: TableBasicInfo | null;
}
