import { Card, CardHeader, CardContent } from "@mui/material";
import DataTable from "../../../../components/DataTable";
import Button from "../../../../components/Button2";
import { useNotifications } from "../../../../components/Notification";

import { BookingPartialPayment } from "../../../../types";
import { STOREFRONT_URL } from "../../../../app.config";

interface PartialPaymentListProps {
  partialPayments: BookingPartialPayment[];
}

export default function PartialPaymentList({
  partialPayments,
}: PartialPaymentListProps) {
  const { showNotification } = useNotifications();

  const columns = [
    { name: "発行日", field: "createdAt" },
    { name: "項目", field: "title" },
    { name: "金額", field: "amount" },
    { name: "お支払状況", field: "status" },
    { name: "", render: renderCopyBtn },
  ];

  return (
    <Card sx={{ marginTop: "16px" }}>
      <CardHeader title="Additional Payment History" />
      <CardContent>
        <DataTable columns={columns} rows={partialPayments} />
      </CardContent>
    </Card>
  );

  function renderCopyBtn(row: BookingPartialPayment) {
    const link = `${STOREFRONT_URL}/partial-payments/${row.id}`;
    return (
      <Button
        color="secondary"
        onClick={() => {
          navigator.clipboard.writeText(link);
          showNotification({
            message: "Link copied to clipboard",
            severity: "success",
          });
        }}
      >
        リンクコピーボタン
      </Button>
    );
  }
}
