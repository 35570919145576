import { Theme, Typography } from "@mui/material";
import { Activity } from "../../../types";
import PageSection from "../../../components/PageSection";
import { CloudinaryImage } from "../../../components/Cloudinary";

import { makeStyles } from "@mui/styles";
import Venue from "./Venue";

const useStyles = makeStyles((theme: Theme) => ({
  itemList: {
    listStyle: "none",
    paddingLeft: theme.spacing(2),
  },
  horizontalItems: {},
  wrap: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: 'flex-start",',
  },
  wrapItem: {
    flexBasis: "48%",
    borderTop: "1px solid #d7d7d7",
  },
}));

export default function ActivityOverview(props: { activity: Activity[] }) {
  const classes = useStyles();
  const { activity } = props;
  return (
    <PageSection lg={12}>
      {activity[0]?.venue && (
        <p>
          Venue: <br />
          <a href={`/venues/${activity[0]?.venue?.id}`}>
            {activity[0]?.venue?.name}
          </a>
        </p>
      )}

      <p>
        Details for venue:
        <br />
        {activity[0]?.detailsForVenue}
      </p>
      {activity[0]?.contract && (
        <p>
          Contract:
          <br />
          Commission rate: {activity[0]?.contract?.commissionRate}
        </p>
      )}
      <div className={classes.wrap}>
        {activity?.map((item, index) => (
          <div key={index} className={classes.wrapItem}>
            <Typography sx={{ marginTop: 2 }} variant="h6">
              {activity[0]?.name}
            </Typography>
            <p>
              <strong>Course menu:</strong>
              <br />
              <ul className={classes.itemList}>
                <li>{item.courseMenu}</li>
              </ul>
            </p>
            {item.priceTypes && (
              <p>
                <strong>Price types:</strong>
                <ul className={classes.itemList}>
                  {item.priceTypes.map((price) => (
                    <li className={classes.horizontalItems}>
                      <Typography variant="h6">{price.name}</Typography>
                      <ul className={classes.itemList}>
                        <li>Amount: {price.amount}</li>
                        <li>Unit type: {price.unitType}</li>
                        <li>Contracted amount: {price.contractedPrice}</li>
                        <li>Currency code: {price.currencyCode}</li>
                        <li>Min. attendees: {price.minAttendees}</li>
                        <li>Max. attendees: {price.maxAttendees}</li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </p>
            )}
            {item.seatOptions && (
              <p>
                <strong>Seat options:</strong>
                <ul className={classes.itemList}>
                  {item.seatOptions.map((seatOption) => (
                    <li>
                      <Typography variant="h6">{seatOption.title}</Typography>
                      <ul className={classes.itemList}>
                        <li>Details: {seatOption.details}</li>
                        <li>
                          Attendees: {seatOption.minAttendees}-
                          {seatOption.maxAttendees}
                        </li>
                        {seatOption.price && <li>Price: {seatOption.price}</li>}
                        <li>
                          {seatOption.photos.map((p) => (
                            <CloudinaryImage
                              key={p.src}
                              src={p.src}
                              width={120}
                              style={{ margin: 3 }}
                              transformations={["w_120"]}
                            />
                          ))}
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </p>
            )}
          </div>
        ))}
      </div>
      {activity[0]?.venue && <Venue venue={activity[0]?.venue} />}
    </PageSection>
  );
}
