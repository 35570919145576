import Grid from "@mui/material/Grid";

import TextField from "../../../../../components/TextField";
import Select from "../../../../../components/Select";
import { Activity, UnitType } from "../../../../../types";

import { useViewBookingContext } from "../..";

interface ItemProps {
  data: ItemType;
  setQuantity: (quantity: number) => void;
  readOnly?: boolean;
  activity?: Activity | null;
  currentSeatQuantity: number;
}

function Item({ data, setQuantity, activity, currentSeatQuantity }: ItemProps) {
  const priceType = getPriceType(data.priceTypeId, activity?.priceTypes);
  const { booking, newPlan } = useViewBookingContext();
  const paymentMethod = booking?.paymentIntent?.method ?? "ON_SITE_PAYMENT";
  const seatOptionMaxAttendees =
    booking?.seatOption?.tableMaxAttendees ?? booking?.seatOption?.maxAttendees;
  const maxAttendees = seatOptionMaxAttendees
    ? seatOptionMaxAttendees + 1 - (currentSeatQuantity ?? 0) + data.quantity ||
    1
    : priceType?.maxAttendees ?? 1;
  const maxQuantityValue =
    paymentMethod === "ON_SITE_PAYMENT"
      ? maxAttendees
      : data.originalQuantity ?? 0;

  const quantityOptions = seatOptionMaxAttendees
    ? [...Array(maxAttendees)]
      .map((_, i) => i)
      .filter((i: number) => {
        return priceType?.maxAttendees !== null
          ? i >= (priceType?.minAttendees ?? 0) &&
          i <= (priceType?.maxAttendees ?? 0)
          : i >= priceType.minAttendees;
      })
      .map((i: number) => {
        return {
          id: i.toString(),
          name: i.toString(),
          disabled: i > maxQuantityValue,
        };
      })
    : [];

  if (
    quantityOptions &&
    quantityOptions.length > 0 &&
    !quantityOptions.find((q) => Number(q.name) == data.quantity)
  ) {
    if (data.quantity < Number(quantityOptions[0].name)) {
      quantityOptions.unshift({
        id: data.quantity.toString(),
        name: data.quantity.toString(),
        disabled: false,
      });
    } else {
      quantityOptions.push({
        id: data.quantity.toString(),
        name: data.quantity.toString(),
        disabled: false,
      });
    }
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            readOnly={true}
            label={"Price Type"}
            value={priceType?.name ?? data.priceType}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            readOnly={true}
            label={"Unit type"}
            value={
              priceType?.unitType
                ? getUnitType(priceType?.unitType)
                : data.unitType
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            label="Quantity"
            disabled={Boolean(newPlan)}
            value={data.quantity.toString()}
            options={quantityOptions}
            onChange={(value: any) => setQuantity(Number(value))}
            style={{ marginTop: "12px" }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            readOnly={true}
            label={"Amount"}
            value={priceType?.amountInMinorUnits ?? data.amount}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Item;

export type ItemType = {
  id?: number;
  priceType: string;
  priceTypeId?: string;
  unitType: string;
  quantity: number;
  amount: number;
  contractedPrice: number;
  originalQuantity?: number;
};

export function getPriceType(
  id: string | undefined,
  priceTypes:
    | {
      id: string;
      unitType: UnitType;
      name: string;
      amount: number;
      amountInMinorUnits?: number;
      contractedPrice: number;
      currencyCode: string;
      minAttendees: number;
      maxAttendees: number;
    }[]
    | undefined
) {
  if (!id || !priceTypes) return;
  const priceType = priceTypes.find((p) => p.id === id) ?? null;
  return priceType;
}

function getUnitType(type: string) {
  return type === "PERSON" ? "名" : "セット";
}
