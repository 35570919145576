import {useState, useEffect, useMemo} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import queryString from "query-string";

import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button2";
import {Card, CardContent, IconButton, Modal} from "@mui/material";
import {VenueTag} from "../../../types";
import EditVenueTag from "./edit";
import {Stack} from "@mui/system";
import moment from "moment-timezone";

export default function VenueTags() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );
  const [selectedTag, setSelectedTag] = useState<VenueTag | undefined | null>(null);
  const openModal = useMemo(() => selectedTag !== null, [selectedTag]);
  const [tagNames, setTagNames] = useState<Set<string>>(new Set());

  useEffect(updateUrl, [page, search, navigate]);

  const { data, loading, error, fetchMore, refetch } = useQuery(VENUE_TAGS_QUERY, {
    variables: {
      offset: 0,
      limit: 10,
      search: search,
    },
    onCompleted: (newData) => {
      if (tagNames.size === 0) {
        setTagNames(new Set(newData?.venueTags?.records?.map((venueTag: VenueTag) => venueTag.name) ?? []));
      }
    },
  });

  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "ID", field: "id" },
    { name: "Name", render: renderName },
    { name: "Created At", render: renderCreatedAt },
    {
      name: "",
      render: (tag: VenueTag) => (
        <Stack direction="row">
          <IconButton onClick={() => setSelectedTag(tag)}>
            <EditIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];
  const rows = data ? data.venueTags.records : undefined;
  console.log(rows)
  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.venueTags.total : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
        topBarExtras={renderTopBarExtras()}
      />
      <Modal open={openModal} onClose={() => setSelectedTag(null)}>
        <Card
          sx={{
            maxWidth: "480px",
            position: "relative",

            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CardContent>
            {selectedTag !== null && (
              <EditVenueTag
                selectedTag={selectedTag}
                tagNames={tagNames}
                onSaveCallback={() => {
                  setSelectedTag(null)
                  setTagNames(new Set())
                }}
                refetch={refetch}
              />
            )}
          </CardContent>
        </Card>
      </Modal>
    </>
  );

  function updateUrl() {
    const urlParams = {
      page: page + 1,
      search: search,
    };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderTopBarExtras() {
    return (
      <div
        style={{
          marginLeft: "0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Button
          style={{backgroundColor: "white", color: "#c8a063"}}
          variant="contained"
          onClick={() => setSelectedTag(undefined)}
          startIcon={<AddIcon/>}
        >
          New
        </Button>
      </div>
      )
    ;
  }

  function renderName(venueTag: VenueTag) {
    return <>{venueTag.name}</>;
  }

  function renderCreatedAt(venueTag: VenueTag) {
    return (
      <>
        {moment(new Date(Number(venueTag.createdAt))).format("YYYY-MM-DD HH:mm")}
      </>
    );
  }
}

const VENUE_TAGS_QUERY = gql`
  query VenueTagsQuery($offset: Int!, $limit: Int!, $search: String) {
    venueTags(offset: $offset, limit: $limit, search: $search) {
      total
      records {
        id
        name
        createdAt
      }
    }
  }
`;
