import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { TableBasicInfo, TableBasicInput } from "../../../types";
import { useEffect, useState } from "react";

import TableRoomBasicInfo from "./TableRoomBasicInfo";

export default function TableRoomInfo() {
  const navigate = useNavigate();
  const { venuesId, id } = useParams<{ venuesId: string; id: string }>();
  const [getTableRoom, { data }] = useLazyQuery(TABLE_ROOM_QUERY);
  const [addTableRoomBasicInfo] = useMutation(ADD_TABLE_ROOM_BASIC_INFO);
  const [editTableRoom] = useMutation(EDIT_TABLE_ROOM);
  const isEdit = id !== "new";
  const [table, setTable] = useState<TableBasicInfo | null>(null);

  useEffect(() => {
    if (isEdit) {
      getTableRoom({ variables: { id, venuesId } });
    }
  }, [isEdit]);

  useEffect(() => {
    if (data?.tableRoom) setTable(data.tableRoom);
  }, [data?.tableRoom]);

  return (
    <TableRoomBasicInfo
      table={table}
      onSubmit={async (table: TableBasicInput) => {
        if (isEdit) {
          await editTableRoom({ variables: { input: table } });
          return;
        }
        await addTableRoomBasicInfo({ variables: { input: table } }).then(
          (data) => {
            if (data?.data?.saveTableRoom?.table?.id) {
              navigate(
                `/table-room/${venuesId}/${data.data.saveTableRoom.table.id}`
              );
            }
          }
        );
      }}
    />
  );
}

const ADD_TABLE_ROOM_BASIC_INFO = gql`
  mutation AddTableRoomBasicInfo($input: SaveTableRoomBasicInfoInput!) {
    saveTableRoom(input: $input) {
      table {
        id
      }
    }
  }
`;

const EDIT_TABLE_ROOM = gql`
  mutation editTableRoomBasicInfo($input: EditTableRoomBasicInfoInput!) {
    editTableRoom(input: $input) {
      success
    }
  }
`;

const TABLE_ROOM_QUERY = gql`
  query TableRoomQuery($venueId: String, $id: String) {
    tableRoom(venueId: $venueId, id: $id) {
      id
      name
      type
      description
      minAttendees
      maxAttendees
      durationTime
      recoveryTime
      tablePhotos {
        id
        photoUrl
      }
      tablePrices {
        id
        price
        holidays
        weekdays
      }
    }
  }
`;
