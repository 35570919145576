import { v4 as uuid } from "uuid";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Paper,
  Divider,
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import ImageGalleryUploader from "../../../../../components/ImageGalleryUploader";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import SeatOptionPricesForm from "./SeatOptionPricesForm";
import { gql, useLazyQuery } from "@apollo/client";

import { TableBasicInfo } from "../../../../../types";
import Select from "../../../../../components/Select";
import { useEffect } from "react";

export type SeatOption = {
  id?: string;
  title?: string;
  details?: string;
  tableId?: string;
  durationTime?: number;
  photos: { src: string }[];
  minAttendees?: number;
  maxAttendees?: number;
  active?: boolean;
  isTableSelect?: boolean;
  price?: number;
  prices?: SeatOptionPriceJson[];
  table?: TableBasicInfo;
};

export type SeatOptionPriceJson = {
  key: string;
  price: number;
  unitType: string;
  weekdays: string[];
  holidays: string[];
};

type Activity = {
  seatOptions?: SeatOption[];
  venue?: {
    id: string;
    toretaStoreId?: string;
  };
};

type ToretaSeatOption = {
  key_name: string;
  display_name: string;
};

type SeatOptionsFormProps = {
  planId: string;
  activity?: Activity;
  onChange: (activity: Activity) => void;
  tableRooms?: TableBasicInfo[];
};

type Fn<T> = (value: T) => T;

function isFunction<T>(value: T | Fn<T>): value is Fn<T> {
  return typeof value === "function";
}

export default function SeatOptionsForm(props: SeatOptionsFormProps) {
  const { activity, onChange, tableRooms } = props;
  const seatOptions = activity?.seatOptions ?? [];
  const [getPlanToretaSeatOptions, { data: toretaSeatOptions }] = useLazyQuery(
    GET_PLAN_TORETA_SEAT_OPTIONS
  );

  function setSeatOptionsField<T>(
    fieldName: keyof SeatOption,
    index: number,
    value: T | Fn<T>,
    table?: TableBasicInfo
  ) {
    const changeable = [...seatOptions];
    const tobechanged = seatOptions[index];
    if (!tobechanged) return;
    const currentValue = (tobechanged[fieldName] as unknown) as T;
    const newValue = isFunction(value) ? value(currentValue) : value;
    const seatOptionTable = {
      title: table?.name,
      details: table?.description,
      maxAttendees: table?.maxAttendees,
      minAttendees: table?.minAttendees,
      photos: table?.tablePhotos.map((photo) => ({ src: photo.photoUrl })),
      prices: table?.tablePrices.map((price) => ({
        key: price.id,
        price: price.price,
        unitType: "ROOM",
        weekdays: price.weekdays,
        holidays: price.holidays,
      })),
    };

    const seatOption = {
      ...tobechanged,
      ...(Boolean(table) ? seatOptionTable : {}),
      [fieldName]: newValue,
    };

    changeable[index] = seatOption;
    onChange({ seatOptions: changeable });
  }

  function setSeatOptionsToreta<T>(index: number, value: string, key: string) {
    const changeable = [...seatOptions];
    const tobechanged = seatOptions[index];
    if (!tobechanged) return;
    const seatOption = {
      ...tobechanged,
      isTableSelect: false,
      title: value,
      details: value,
      maxAttendees: 24,
      minAttendees: 1,
      photos: [],
      prices: [
        {
          key: key,
          price: 0,
          unitType: "ROOM",
          weekdays: [],
          holidays: ["SU", "MO", "TU", "WE", "TH", "FR", "SA"],
        },
      ],
    };

    changeable[index] = seatOption;
    onChange({ seatOptions: changeable });
  }

  const removeSeat = (s: SeatOption) => {
    let seatList = seatOptions.filter((a) => a.id !== s.id);

    onChange({ seatOptions: seatList });
  };

  useEffect(() => {
    if (activity?.venue?.toretaStoreId) {
      getPlanToretaSeatOptions({
        variables: { toretaStoreId: activity?.venue?.toretaStoreId },
      });
    }
  }, [activity]);
  console.log(seatOptions);

  return (
    <Card>
      <CardHeader
        title="Seat options"
        action={
          <IconButton
            onClick={() => {
              onChange({
                seatOptions: seatOptions.concat({
                  id: uuid(),
                  active: false,
                  price: 0,
                  photos: [],
                  table: undefined,
                }),
              });
            }}
          >
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        {seatOptions.map((s, index) => (
          <Grid key={index} container spacing={2}>
            <Grid item lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={s.active ?? false}
                    onChange={() =>
                      setSeatOptionsField("active", index, !s.active)
                    }
                  />
                }
                label={"Active Seat Option?"}
              />
              <FormControl style={{ width: "100%", marginBottom: 4 }}>
                <RadioGroup
                  row
                  defaultValue="1"
                  value={
                    activity?.venue?.toretaStoreId
                      ? "3"
                      : s.isTableSelect
                        ? "1"
                        : "2"
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSeatOptionsField(
                      "isTableSelect",
                      index,
                      e.target.value == "1" ? true : false
                    );
                  }}
                >
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    label={"Table select"}
                    disabled={Boolean(activity?.venue?.toretaStoreId)}
                  />
                  <FormControlLabel
                    value={"2"}
                    control={<Radio />}
                    label={"Seat option"}
                    disabled={Boolean(activity?.venue?.toretaStoreId)}
                  />
                  <FormControlLabel
                    value={"3"}
                    control={<Radio />}
                    label={"Toreta Seat option"}
                    disabled={!Boolean(activity?.venue?.toretaStoreId)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {activity?.venue?.toretaStoreId ? (
              <Grid container spacing={2} sx={{ padding: "10px 18px 0 18px" }}>
                <Grid item lg={4}>
                  <Select
                    required
                    label={`SEAT ${index + 1}`}
                    value={s.title}
                    options={
                      toretaSeatOptions?.getPlanToretaSeatOptions
                        ? toretaSeatOptions?.getPlanToretaSeatOptions.map(
                          (option: ToretaSeatOption) => ({
                            id: option.display_name,
                            name: option.display_name,
                            disabled: Boolean(
                              seatOptions.find(
                                (s) => s.title === option.display_name
                              )
                            ),
                          })
                        )
                        : []
                    }
                    onChange={(e: any) => {
                      const selectOption = toretaSeatOptions?.getPlanToretaSeatOptions?.find(
                        (option: ToretaSeatOption) => option.display_name === e
                      );
                      setSeatOptionsToreta(index, e, selectOption.key_name);
                    }}
                  />
                </Grid>
              </Grid>
            ) : s.isTableSelect ? (
              <Grid container spacing={2} sx={{ padding: "10px 18px 0 18px" }}>
                <Grid item lg={4}>
                  <Select
                    required
                    label={`SEAT ${index + 1}`}
                    value={s.tableId ?? ""}
                    options={
                      tableRooms
                        ? tableRooms.map((room) => ({
                          id: room.id,
                          name: room.name,
                        }))
                        : []
                    }
                    onChange={(e: any) => {
                      setSeatOptionsField(
                        "tableId",
                        index,
                        e,
                        tableRooms?.find((table) => table.id === e)
                      );
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    multiline
                    type="number"
                    id="DURATION-TIME"
                    label="DURATION TIME"
                    value={s.durationTime ?? ""}
                    fullWidth
                    onChange={(e: any) => {
                      setSeatOptionsField(
                        "durationTime",
                        index,
                        Number(e.target.value)
                      );
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} sx={{ paddingLeft: 2 }}>
                <Grid item lg={6}>
                  <TextField
                    required
                    multiline
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    label="Title"
                    value={s.title}
                    onChange={(e: any) => {
                      setSeatOptionsField("title", index, e.target.value);
                    }}
                  />
                  <TextField
                    required
                    sx={{ marginBottom: 2 }}
                    multiline
                    fullWidth
                    label="Details"
                    value={s.details}
                    onChange={(e: any) => {
                      setSeatOptionsField("details", index, e.target.value);
                    }}
                  />
                  <TextField
                    required
                    multiline
                    sx={{ marginBottom: 2 }}
                    fullWidth
                    label="Min. attendees"
                    value={s.minAttendees}
                    onChange={(e: any) => {
                      setSeatOptionsField(
                        "minAttendees",
                        index,
                        Number(e.target.value) || undefined
                      );
                    }}
                  />
                  <TextField
                    required
                    multiline
                    sx={{ marginBottom: 2 }}
                    fullWidth
                    label="Max. attendees"
                    value={s.maxAttendees}
                    onChange={(e: any) => {
                      setSeatOptionsField(
                        "maxAttendees",
                        index,
                        Number(e.target.value) || undefined
                      );
                    }}
                  />
                  <SeatOptionPricesForm
                    seatOption={s}
                    seatOptionIdx={index}
                    onChange={setSeatOptionsField}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    width: "auto",
                    height: "auto",
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{
                      width: "auto",
                      height: "auto",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <ImageGalleryUploader
                      key={s.id}
                      images={s.photos.map((p) => ({
                        id: "",
                        imageUrl: p.src,
                      }))}
                      uploadPath={`plans/${props.planId}/seat-options/${s.id}`}
                      allowDnd={false}
                      gridSizes={{ xs: 12, md: 4 }}
                      onChangeFunction={(photos) => {
                        setSeatOptionsField(
                          "photos",
                          index,
                          photos.map((p) => ({ src: p.imageUrl }))
                        );
                      }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}
            <Grid item lg={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => removeSeat(s)}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{ width: "auto", marginTop: 12, marginBottom: 12 }}
              />
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
}

const GET_PLAN_TORETA_SEAT_OPTIONS = gql`
  query getPlanToretaSeatOptions($toretaStoreId: String!) {
    getPlanToretaSeatOptions(toretaStoreId: $toretaStoreId) {
      key_name
      display_name
    }
  }
`;
