import { useParams, useNavigate } from "react-router-dom";
import Schedule from "./../Schedule";
import TableRoomInfo from "./TableRoomInfo";
import DataTabs from "../../../components/DataTabs";
import { Paper, Tab, Tabs } from "@mui/material";

const TabIndices: Record<string, number> = {
  room: 0,
  schedule: 1,
};

export default function NewTableRoom() {
  const { venuesId, id } = useParams<{ venuesId: string; id: string }>();
  const navigate = useNavigate();
  const tabs = ["Basic info", "Photos", "Schedule", "Table(Room)"];
  const tabLinks = {
    0: `/venues/${venuesId}/edit/basicInfo`,
    1: `/venues/${venuesId}/edit/photos`,
    2: `/venues/${venuesId}/edit/schedule`,
    3: `/venues/${venuesId}/edit/overview`,
  };

  return (
    <>
      <Paper style={{ marginBottom: "10px" }}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#c8a063" } }}
          value={3}
          onChange={(_event: any, newValue: number) => {
            navigate(tabLinks[newValue as 0 | 1 | 2 | 3]);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab) => (
            <Tab
              onClick={() => {
                if (tab === tabs[3]) {
                  navigate(`/venues/${venuesId}/edit/overview`);
                }
              }}
              key={tab}
              label={tab}
            />
          ))}
        </Tabs>
      </Paper>
      <DataTabs
        selectedTab={0}
        tabs={[
          {
            title: "Room",
            content: <TableRoomInfo />,
          },
          ...(id !== "new"
            ? [
                {
                  title: "Schedule",
                  content: <Schedule />,
                },
              ]
            : []),
        ]}
      />
    </>
  );
}
