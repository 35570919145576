import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  RadioGroup,
  FormControlLabel, Radio,
} from "@mui/material";
import TextField from "../../../../components/TextField";
import Button from "../../../../components/Button2";
import { useNotifications } from "../../../../components/Notification";

import { Activity, Booking } from "../../../../types";

interface PartialPaymentFormProps {
  booking: Booking;
  seatPrice?: number;
  reservation?: any;
  refetch: () => void;
}

export function getActivity(id: string, activities: Activity[] | undefined) {
  if (!activities) return null;
  const activity = activities.find((a: Activity) => a.id === id) ?? null;
  return activity;
}

export default function PartialPaymentForm({
  booking,
  seatPrice,
  reservation,
  refetch,
}: PartialPaymentFormProps) {
  const { showNotification } = useNotifications();

  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [defaultValue, setDefault] = useState<string | null>('');
  const [addPrice, setAddPrice] = useState<string | null>('');

  useEffect(() => {
    if (defaultValue != '') {
      let changePrice = ''
      if (defaultValue == 'seatOption') {
        console.log("import price: " + amount);
        if (amount) {
          changePrice = String((seatPrice ? seatPrice : 0) + (amount ? amount : 0))
        }
        console.log("Price subject to change: " + changePrice);
        setAddPrice(String(changePrice))
      } else {
        if (reservation.records.length > 0) {
          const firstReservation = reservation.records[0];
          const firstPriceType = firstReservation.items.find((item: any) => item.quantity > 0);

          if (firstPriceType) {
            let quantity = Number(firstPriceType.quantity);
            const amountInMinorUnits = booking.planSnapshot.activities
              .map((activity: any) => {
                return activity.priceTypes.find((pt: any) => String(pt.id) == String(firstPriceType.priceTypeId));
              })
              .filter(Boolean)
              .map((pt: any) => pt.amountInMinorUnits);

            if (amountInMinorUnits.length > 0) {
              const amountOld = Number(amountInMinorUnits[0]);

              changePrice = String((amountOld * quantity + (amount ? amount : 0)) / quantity);
            }
          }
        }
        setAddPrice(changePrice)
      }
    }
  }, [defaultValue, amount]);

  const [savePartialPayment] = useMutation(SAVE_PARTIAL_PAYMENT, {
    async update() {
      setTitle("");
      setAmount(0);
      await refetch();
    },
  });

  const handleSavePartialPayment = async () => {
    setIsSaving(true);

    if (!title || !amount) {
      showNotification({
        message: "Missing required data.",
        severity: "error",
      });
      return;
    }

    let typeChange = 1;

    if (defaultValue != 'seatOption') {
      typeChange = 2;
    }

    savePartialPayment({
      variables: {
        input: {
          bookingId: booking.id,
          title,
          amount,
          typeChange,
        },
      },
    })
      .then((response) => {
        const success =
          response.data?.saveBookingPartialPayment?.success || false;

        showNotification({
          message: success
            ? "Additional payment created successfully"
            : "Failed to save additional payment",
          severity: success ? "success" : "error",
        });
      })
      .catch(() => {
        showNotification({
          message: "Something went wrong.",
          severity: "error",
        });
        return;
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <Card>
      <CardHeader title="Additional Payment" />
      <CardContent>
        <Typography>
          追加支払い用のリンクを発行するには以下を
          入力し、ボタンを押してください。お客様へ
          メール等でリンクの送付をおねがいします。
        </Typography>
        <TextField
          label={"項目"}
          value={title}
          placeholder="例：人数増加に伴う追加ご請求"
          onChange={(e: any) => {
            setTitle(e.target.value);
          }}
          error={isSaving && !title}
        />
        <TextField
          type="number"
          label={"金額"}
          value={amount}
          placeholder="例：12000（税込みの整数で記入）"
          InputProps={{
            endAdornment: <span>¥</span>,
          }}
          onChange={(e: any) => {
            const value = e.target.value;
            setAmount(value ? Number(e.target.value) : value);
          }}
          error={isSaving && !amount}
        />
        <RadioGroup
          aria-label="tag-filter"
          name="tag-filter"
          value={defaultValue ?? 'seatOption'}
          onChange={(e) => {
            setDefault(e.target.value)
          }}
        >
          <FormControlLabel
            value="seatOption"
            control={<Radio />}
            label={defaultValue == 'seatOption' && amount ? "Seat Options: Amount after recalculation " + addPrice : 'Seat Options'}
          />
          <FormControlLabel
            value="priceType"
            control={<Radio />}
            label={defaultValue == 'priceType' && amount ? "Price Type: Amount after recalculation " + addPrice : 'Price Type'}
          />
        </RadioGroup>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="primary" onClick={handleSavePartialPayment}>
          リンクを発行する
        </Button>
      </CardActions>
    </Card>
  );
}

const SAVE_PARTIAL_PAYMENT = gql`
  mutation SavePartialPayment($input: SaveBookingPartialPaymentInput!) {
    saveBookingPartialPayment(input: $input) {
      success
      partialPayment {
        id
        bookingId
        title
        amount
        status
        method
        paidAt
        createdAt
      }
    }
  }
`;
