import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";

export default function Select(props: SelectProps) {
  return (
    <FormControl
      required={props.required ?? false}
      variant="outlined"
      style={{ width: "100%", ...props.style }}
      margin={props.size === "small" ? "dense" : "none"}
    >
      <InputLabel id="select-label">{props.label}</InputLabel>
      <MuiSelect
        labelId="select-label"
        label={props.label}
        disabled={props.disabled}
        value={props.value ? props.value : ""}
        onChange={(e: any) => props.onChange(e.target.value)}
      >
        {props.options.map((o) => (
          <MenuItem
            key={o.id}
            value={o.id}
            disabled={o.disabled ? true : false}
          >
            {o.name}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

interface SelectProps {
  required?: boolean;
  label: string;
  value?: string;
  disabled?: boolean;
  options: { id: string; name: string; disabled?: boolean }[];
  onChange: (value: any) => void;
  style?: any;
  size?: "small" | "medium";
}
