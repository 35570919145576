import { useState, useEffect } from "react";
import TextField from "../../../components/TextField";
import PageSection from "../../../components/PageSection";
import { Grid, Button } from "@mui/material";
import { TableBasicInfo, TableBasicInput } from "../../../types";
import ImageGalleryUploader from "../../../components/ImageGalleryUploader";
import { v4 as uuid } from "uuid";
import TableRoomPricesForm from "./TableRoomPricesForm";
import { useParams } from "react-router-dom";
import { useNotifications } from "../../../components/Notification";
import Select from "../../../components/Select";

type BasicInfoFormProps = {
  table?: TableBasicInfo | null;
  onSubmit: (table: TableBasicInput) => Promise<void>;
};

type TablePrice = {
  id: string;
  price: number;
  unitType: string;
  weekdays: string[];
  holidays: string[];
};

const defaultSeatOption = {
  id: undefined,
  title: undefined,
  details: undefined,
  photos: [],
  minAttendees: undefined,
  maxAttendees: undefined,
  active: undefined,
  price: undefined,
  prices: [
    {
      key: uuid(),
      price: 0,
      unitType: "ROOM",
      weekdays: [],
      holidays: [],
    },
  ],
};

const typeOptions = [
  {
    id: "個室(窓有)",
    name: "個室(窓有)",
  },
  {
    id: "個室(窓無)",
    name: "個室(窓無)",
  },
  {
    id: "テーブル席(窓側)",
    name: "テーブル席(窓側)",
  },
  {
    id: "テーブル席",
    name: "テーブル席",
  },
  {
    id: "VIP席",
    name: "VIP席",
  },
];

export default function TableRoomBasicInfo(props: BasicInfoFormProps) {
  const { table } = props;

  const { venuesId, id } = useParams<{ venuesId: string; id: string }>();
  const { showNotification } = useNotifications();
  const isEdit = id !== "new";

  const [name, setName] = useState<string>("");
  const [images, setImages] = useState<any>([]);
  const [minAttendees, setMinAttendees] = useState<number | null>(0);
  const [maxAttendees, setMaxAttendees] = useState<number | null>(0);
  const [type, setType] = useState<string>("");
  const [recoveryTime, setRecoveryTime] = useState<number | null>(0);
  const [durationTime, setDurationTime] = useState<number | null>(0);
  const [description, setDescription] = useState<string>("");
  const [tableRoom, setTableRoom] = useState<any>(defaultSeatOption);
  const [deletePriceIds, setDeletePriceIds] = useState<string[]>([]);
  const [deletePhotoIds, setDeletePhotoIds] = useState<string[]>([]);

  function setPriceField(a: any, b: any, prices: any) {
    setTableRoom((prev: any) => {
      return { ...prev, prices };
    });
  }

  function saveTableRoom<T>(obj: T): T {
    const filteredEntries = Object.entries(obj).filter(
      ([_, v]) => Boolean(v) || v === 0
    );
    return Object.fromEntries(filteredEntries) as T;
  }

  useEffect(() => {
    if (table) {
      setName(table.name);
      setType(table.type);
      setMinAttendees(table.minAttendees);
      setMaxAttendees(table.maxAttendees);
      setRecoveryTime(table.recoveryTime);
      setDurationTime(table.durationTime);
      setDescription(table.description);
      setImages(
        table.tablePhotos.map((image) => ({
          imageUrl: image.photoUrl,
          id: image.id,
        }))
      );
      setPriceField(
        null,
        null,
        table.tablePrices.map((p) => ({
          key: p.id,
          price: p.price,
          unitType: "ROOM",
          weekdays: p.weekdays,
          holidays: p.holidays,
        }))
      );
    }
  }, [table]);

  const checkPrices = (prices: TablePrice[]) => {
    return prices.find(
      (price: TablePrice) =>
        price.weekdays.length === 0 && price.holidays.length === 0
    );
  };

  return (
    <Grid container>
      <PageSection lg={12}>
        <Button
          style={{ backgroundColor: "#c8a063", color: "white" }}
          variant="contained"
          onClick={() => {
            if (!name) {
              showNotification({
                message: "Please enter table room name!",
                severity: "error",
              });
              return;
            }

            if (name.length > 100) {
              showNotification({
                message: "Table name cannot be longer than 100 characters!",
                severity: "error",
              });
              return;
            }

            if (!type) {
              showNotification({
                message: "Please enter table room type!",
                severity: "error",
              });
              return;
            }

            if (!images || images.length == 0) {
              showNotification({
                message: "Please enter table room images!",
                severity: "error",
              });
              return;
            }

            if (checkPrices(tableRoom.prices)) {
              showNotification({
                message: "Invalid table room price!",
                severity: "error",
              });
              return;
            }

            props
              .onSubmit(
                saveTableRoom({
                  id: isEdit ? table?.id : uuid(),
                  name,
                  description,
                  minAttendees,
                  maxAttendees,
                  durationTime,
                  recoveryTime,
                  venuesId,
                  type,
                  photos: images,
                  prices: tableRoom.prices,
                  ...(isEdit ? { deletePriceIds: deletePriceIds } : {}),
                  ...(isEdit ? { deletePhotoIds: deletePhotoIds } : {}),
                }) as TableBasicInput
              )
              .then(() => {
                showNotification({
                  message: "Basic info saved successfully",
                  severity: "success",
                });
              })
              .catch((err: any) => {
                let message: string = `Could not save form. ${err.message}`;
                if (err.networkError)
                  message = `Could not save form. Please make sure to fill all required fields, ${
                    err.networkError.result.errors[0].extensions.code
                  } - ${err.networkError.result.errors[0].message.substring(
                    0,
                    501
                  )}`;
                showNotification({
                  message,
                  severity: "error",
                });
              });
          }}
        >
          Save
        </Button>
      </PageSection>
      <Grid item lg={6}>
        <PageSection>
          <TextField
            required
            label="Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
          />
          <TextField
            required
            type="number"
            id="MIN. Attendees"
            label="MIN. Attendees"
            value={minAttendees || 0}
            onChange={(e: any) => {
              setMinAttendees(Number(e.target.value));
            }}
          />
          <TextField
            required
            type="number"
            id="MAX. Attendees"
            label="MAX. Attendees"
            value={maxAttendees || 0}
            onChange={(e: any) => {
              setMaxAttendees(Number(e.target.value));
            }}
          />
          <Select
            required
            style={{ marginTop: "10px" }}
            label="Table Type"
            value={type}
            options={typeOptions.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            })}
            onChange={(option) => {
              setType(option);
            }}
          />
        </PageSection>
        <PageSection>
          <TableRoomPricesForm
            seatOption={tableRoom}
            seatOptionIdx={0}
            onDelete={(key: string) => {
              setDeletePriceIds([...deletePriceIds, key]);
            }}
            onChange={setPriceField}
          />
        </PageSection>
      </Grid>
      <Grid item lg={6}>
        <PageSection>
          <TextField
            required
            type="number"
            id="Recovery_Time"
            label="Recovery Time"
            value={recoveryTime || 0}
            onChange={(e: any) => {
              setRecoveryTime(Number(e.target.value));
            }}
          />
          <TextField
            required
            type="number"
            id="Duration_Time"
            label="Duration Time"
            value={durationTime || 0}
            onChange={(e: any) => {
              setDurationTime(Number(e.target.value));
            }}
          />
          <TextField
            multiline={true}
            minRows={5}
            label="Description"
            value={description}
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
          />
        </PageSection>
        <PageSection>
          <div style={{ minHeight: "54px" }}>
            <ImageGalleryUploader
              uploadPath={`plans/table-rooms/${props.table?.id}`}
              allowDnd={false}
              gridSizes={{ xs: 12, md: 4 }}
              images={images}
              onChangeFunction={(images) => {
                setImages(images);
              }}
              onDeleteFunction={(id: string) => {
                setDeletePhotoIds([...deletePhotoIds, id]);
              }}
              isChangeable={true}
            />
          </div>
        </PageSection>
      </Grid>
    </Grid>
  );
}
