import {
  Chip,
  Divider,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import FilterList from "@mui/icons-material/FilterList";
import { useCallback, useMemo, useState } from "react";

export enum BookingFailLogStatus {
  NO_PAYMENT_MADE = 0,
  SYSTEM_ERROR = 1,
}

const statusTags = Object.keys(BookingFailLogStatus)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({
    key: key as keyof typeof BookingFailLogStatus,
    value: BookingFailLogStatus[key as keyof typeof BookingFailLogStatus],
  }));

export default function TagSelect({
  status,
  setStatus,
  onChangeCallback,
}: TagSelectProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  type TagMenuItem<T> = {
    tag: T;
    label: string;
    setFn: (x: T) => void;
  };

  const selectedTags = useMemo(
    () =>
      status !== null
        ? [
          {
            tag: status,
            label: BookingFailLogStatus[status as unknown as keyof typeof BookingFailLogStatus],
            setFn: setStatus,
          },
        ]
        : [],
    [status, setStatus]
  );

  console.log(selectedTags);

  const getMenuItems = useCallback(
    (tags: { key: keyof typeof BookingFailLogStatus; value: number }[], setFn: (x: number | null) => void) =>
      tags.map(({ key, value }) => (
        <MenuItem
          key={value}
          value={value}
          onClick={() => {
            setFn(selectedTags.some((x) => x.tag === value) ? null : value);
            onChangeCallback();
          }}
          selected={selectedTags.some((x) => x.tag === value)}
        >
          {key}
        </MenuItem>
      )),
    [onChangeCallback, selectedTags]
  );

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        {selectedTags.map((selected) => (
          <Chip
            key={selected.tag}
            onDelete={() => {
              selected.setFn(null);
              onChangeCallback();
            }}
            label={selected.label}
          />
        ))}
        <Tooltip title="Status filter" placement="top">
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              setAnchorEl(event.currentTarget)
            }
          >
            <FilterList />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ListSubheader>Booking Status</ListSubheader>
        {getMenuItems(statusTags, setStatus)}
        <Divider />
      </Menu>
    </div>
  );
}

export type TagSelectProps = {
  status: BookingFailLogStatus | null;
  setStatus: (status: BookingFailLogStatus | null) => void;
  onChangeCallback: () => void;
};
