import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Schedule, {
  SAVE_SCHEDULE,
  SaveScheduleInput,
  SaveScheduleResponse,
  ScheduleInput,
} from "../../../components/Schedule";
import { Box, Button, CircularProgress } from "@mui/material";
import { useNotifications } from "../../../components/Notification";
import { useState } from "react";

export default function VenueView() {
  const { showNotification } = useNotifications();
  const { id, venuesId } = useParams<{ venuesId: string; id: string }>();
  const { loading, error, data } = useQuery(TABLE_SCHEDULE_QUERY, {
    variables: { tableId: id },
    fetchPolicy: "network-only",
  });
  const [saveSchedule] = useMutation<SaveScheduleResponse, SaveScheduleInput>(
    SAVE_SCHEDULE
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [publishTableAvailability] = useMutation<
    PublishTableAvailabilityResponse,
    PublishTableAvailabilityInput
  >(PUBLISH_TABLE_AVAILABILITY);

  if (loading || error || !data || !data.tableSchedule) {
    return null;
  }

  const { tableSchedule } = data;

  const handleSaveSchedule = async (schedule: ScheduleInput) => {
    if (!id) return;
    await saveSchedule({
      variables: {
        input: {
          resourceId: id!,
          schedule,
        },
      },
    })
      .then(() => {
        showNotification({
          severity: "success",
          message: "Schedule was saved",
        });
      })
      .catch((err) => {
        showNotification({
          severity: "error",
          message: err.message,
        });
      });
  };

  return (
    <>
      <Box sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{
            backgroundColor: "#c8a063",
            color: "white",
            minHeight: "40px",
          }}
          disabled={saving}
          variant="contained"
          onClick={async () => {
            setSaving(true);
            await publishTableAvailability({
              variables: {
                input: { tableId: id!, venueId: venuesId! },
              },
            })
              .then(() => {
                showNotification({
                  severity: "success",
                  message: "Publish table availability successffuly.",
                });
              })
              .catch((err) => {
                showNotification({
                  severity: "error",
                  message: err.message,
                });
              });
            setSaving(false);
          }}
        >
          {saving ? <CircularProgress size={16} /> : "Save"}
        </Button>
      </Box>
      <Box sx={{ paddingBottom: 2, marginTop: 2 }}>
        <Schedule resource={tableSchedule} onSave={handleSaveSchedule} />
      </Box>
    </>
  );
}

type PublishTableAvailabilityInput = {
  input: {
    tableId: string;
    venueId: string;
  };
};

type PublishTableAvailabilityResponse = {
  publishedAt: string;
};

const PUBLISH_TABLE_AVAILABILITY = gql`
  mutation PublishTableAvailabilityMutation(
    $input: PublishTableAvailabilityInput!
  ) {
    publishTableAvailability(input: $input) {
      publishedAt
    }
  }
`;

const TABLE_SCHEDULE_QUERY = gql`
  query TableScheduleQuery($tableId: ID!) {
    tableSchedule(tableId: $tableId) {
      id
      schedule {
        recurrenceRules {
          weekdays
          holidays
          hours
          minutes
        }
        cutoffTime {
          hours
        }
        bookablePeriod {
          months
        }
        closedDates {
          moment
          locked
        }
        closedTimeSlots
        closedHolidays
        openDates
        openTimeSlots
      }
    }
  }
`;
