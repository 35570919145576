import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import queryString from "query-string";

import DataTable from "../../../../components/DataTable";
import Button from "../../../../components/Button2";
import { IconButton } from "@mui/material";
import DeleteTableRoomDialog from "./DeleteTableRoomDialog";
import { TableBasicInfo } from "../../../../types";

export default function TableList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );
  const [
    tableRoomToBeRemoved,
    setTableRoomToBeRemoved,
  ] = useState<TableBasicInfo | null>(null);

  useEffect(updateUrl, [page, search, navigate]);

  const { id } = useParams<{ id: string }>();

  const { data, loading, error, fetchMore } = useQuery(TABLE_ROOM_QUERY, {
    variables: { offset: 0, limit: 10, search: search, venuesId: id },
  });

  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "ID", field: "id" },
    { name: "Room", render: renderRoom },
    { name: "", render: renderDeleteVenue },
  ];

  const rows = data ? data?.tableRooms?.records : undefined;

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.tableRooms.total : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
                venuesId: id,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
        topBarExtras={renderTopBarExtras()}
      />
      <DeleteTableRoomDialog
        onClose={() => setTableRoomToBeRemoved(null)}
        open={Boolean(tableRoomToBeRemoved)}
        tableRoom={tableRoomToBeRemoved}
      />
    </>
  );

  function updateUrl() {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderTopBarExtras() {
    return (
      <Button
        style={{ backgroundColor: "white", color: "#c8a063" }}
        variant="contained"
        component={Link}
        to={`/table-room/${id}/new`}
        startIcon={<AddIcon />}
      >
        New
      </Button>
    );
  }

  function renderRoom(tableRoom: TableBasicInfo) {
    return <>{tableRoom.name}</>;
  }

  function renderDeleteVenue(venue: TableBasicInfo) {
    return (
      <>
        <IconButton component={Link} to={`/table-room/${id}/${venue.id}`}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => setTableRoomToBeRemoved(venue)}>
          <DeleteIcon />
        </IconButton>
      </>
    );
  }
}

const TABLE_ROOM_QUERY = gql`
  query TableRoomQuery(
    $offset: Int!
    $limit: Int!
    $search: String
    $venuesId: String
  ) {
    tableRooms(
      offset: $offset
      limit: $limit
      search: $search
      venuesId: $venuesId
    ) {
      total
      records {
        id
        name
        nameFurigana
        plans {
          id
          name
        }
        publishedPlans {
          id
          name
        }
      }
    }
  }
`;

export interface tableRoom {
  id: string;
  name: string;
}
