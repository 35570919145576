import { useMutation, gql } from "@apollo/client";
import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";

import ImageGallery from "./Image";
import UploadingImage from "./UploadingImage";
import { useNotifications } from "../Notification";
import { v4 as uuid } from "uuid";
import { PhotoCategories, PhotoCategory } from "../../types";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Button, Grid, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { imageResolution } from "../../helpers/image-resolution";

export type ImageType = {
  id: string;
  imageUrl: string;
  category?: PhotoCategory;
};

interface ImageGalleryUploaderProps {
  images: ImageType[];
  uploadPath: string;
  allowDnd?: boolean;
  useImageCategory?: boolean;
  gridSizes?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  saveImagesFunction?: (images: ImageType[]) => void;
  onChangeFunction?: (images: ImageType[]) => void;
  onDeleteFunction?: (id: string) => void;
  isDisableButton?: boolean;
  isChangeable?: boolean;
}

export default function ImageGalleryUploader(props: ImageGalleryUploaderProps) {
  const { showNotification } = useNotifications();

  const grid = props.gridSizes;

  const [inputFile, setInputFile] = useState("");
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState<ImageType[]>(props.images);
  const [imageResolutions, setImageResolutions] = useState<any>({});

  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION);

  useEffect(() => {
    if (props.isChangeable) {
      setImages(props.images ?? []);
    }
  }, [props.images]);

  useEffect(() => {
    const fetchImageResolutions = async () => {
      const resolutions: any = {};

      for (const photo of images) {
        try {
          const { width, height } = await imageResolution(photo.imageUrl);
          resolutions[photo.imageUrl] = {
            width: width,
            height: height,
          };
        } catch (error) {
          resolutions[photo.imageUrl] = "Error loading image";
        }
      }

      setImageResolutions(resolutions);
    };

    fetchImageResolutions();
  }, [images]);

  return (
    <>
      <Grid
        style={{ marginBottom: "5px", justifyContent: "flex-end" }}
        container
        spacing={2}
      >
        <Grid item>
          {!props.isDisableButton && (
            <Button
              style={{ backgroundColor: "white", color: "#c8a063" }}
              variant="contained"
              startIcon={uploading ? null : <AddIcon />}
              disabled={uploading ? true : false}
              component="label"
            >
              {uploading ? "Uploading..." : "Add new photo(s)"}
              <input
                type="file"
                multiple
                value={inputFile}
                hidden
                onChange={handleUpload}
              />
            </Button>
          )}
        </Grid>
        {props.saveImagesFunction && (
          <Grid item>
            <Button
              style={{ backgroundColor: "#c8a063", color: "white" }}
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => handleSavePhotos(images)}
            >
              Save images
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <DndProvider backend={HTML5Backend}>
          {images.map((photo: ImageType, index: number) => (
            <Grid
              item
              key={photo.imageUrl}
              xs={grid?.xs}
              sm={grid?.sm}
              md={grid?.md}
              lg={grid?.lg}
              xl={grid?.xl}
            >
              <ImageGallery
                index={index}
                src={photo.imageUrl}
                allowDnd={props.allowDnd}
                removeFunction={handleRemovePhoto}
                moveUpFunction={
                  index !== 0 ? () => handleMoveUp(index) : undefined
                }
                moveDownFunction={
                  index !== images.length - 1
                    ? () => handleMoveDown(index)
                    : undefined
                }
                moveFunction={handleMove}
              />

              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                {photo.category ? (
                  <Select
                    value={photo.category}
                    label="Category"
                    onChange={(e) => handleCategory(e, index)}
                  >
                    {PhotoCategories.map((v, k) => (
                      <MenuItem value={v} key={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
                <Box
                  sx={{
                    textAlign: "right",
                    width: "100%",
                    color:
                      imageResolutions[photo.imageUrl]?.width +
                        imageResolutions[photo.imageUrl]?.height <=
                      1000
                        ? "#1976d2"
                        : "red",
                  }}
                >
                  {imageResolutions ? (
                    <>
                      <Box>{`${
                        imageResolutions[photo.imageUrl]?.width
                          ? imageResolutions[photo.imageUrl]?.width
                          : "..."
                      } x ${
                        imageResolutions[photo.imageUrl]?.height
                          ? imageResolutions[photo.imageUrl]?.height
                          : "..."
                      }`}</Box>
                      {imageResolutions[photo.imageUrl]?.width +
                        imageResolutions[photo.imageUrl]?.height <=
                      1000 ? (
                        <Box>低解像度注意</Box>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    "Loading..."
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </DndProvider>
        {uploading && (
          <Grid
            item
            xs={grid?.xs}
            sm={grid?.sm}
            md={grid?.md}
            lg={grid?.lg}
            xl={grid?.xl}
          >
            <UploadingImage />
          </Grid>
        )}
      </Grid>
    </>
  );

  function handleUpload(event: { target: HTMLInputElement }) {
    setUploading(true);
    const files = event.target.files;
    if (!files) return;

    const fileSizesOk = checkFileSizes(files);
    if (!fileSizesOk) {
      showNotification({
        message: "Uploading image(s) failed, file size limit is 10MB",
        severity: "error",
      });
      setUploading(false);
      return;
    }

    Promise.all(
      Array.from(files).map((f: File) =>
        uploadFile({ variables: { file: f, path: props.uploadPath } })
      )
    )
      .then((response) => {
        if (
          props.useImageCategory === false ||
          props.useImageCategory === undefined
        ) {
          changeImages([
            ...images,
            ...response.map((r) => ({
              imageUrl: r.data.uploadFile.fileLocation,
              id: uuid(),
            })),
          ]);
        } else {
          changeImages([
            ...images,
            ...response.map((r) => ({
              imageUrl: r.data.uploadFile.fileLocation,
              id: uuid(),
              category: PhotoCategories[0],
            })),
          ]);
        }
        showNotification({ message: "Image(s) uploaded", severity: "success" });
      })
      .catch((error: any) =>
        showNotification({
          message: `Uploading image failed, ${error}`,
          severity: "error",
        })
      )
      .finally(() => {
        setUploading(false);
        setInputFile(""); // Reset file input
      });
  }

  function checkFileSizes(files: FileList) {
    const imageMaxSize = 10000000; // 10MB

    for (const file of files) {
      if (file.size > imageMaxSize) return false;
    }

    return true;
  }

  function handleSavePhotos(images: ImageType[]) {
    if (props.saveImagesFunction) props.saveImagesFunction(images);
  }

  function handleRemovePhoto(imageUrl: string) {
    const imageDelete = images.find((image) => image.imageUrl === imageUrl);
    changeImages(images.filter((image) => image.imageUrl !== imageUrl));
    if (imageDelete && props.onDeleteFunction)
      props.onDeleteFunction(imageDelete.id);
  }

  function handleMoveUp(index: number) {
    changeImages([
      ...images.slice(0, index - 1)!,
      images[index]!,
      images[index - 1]!,
      ...images.slice(index + 1)!,
    ]);
  }

  function handleMoveDown(index: number) {
    changeImages([
      ...images.slice(0, index)!,
      images[index + 1]!,
      images[index]!,
      ...images.slice(index + 2)!,
    ]);
  }

  function handleMove(sourceImg: string, targetImg: string) {
    if (sourceImg === targetImg) return;

    const sourceIndex = images.findIndex((i) => i.imageUrl === sourceImg);
    const targetIndex = images.findIndex((i) => i.imageUrl === targetImg);

    if (sourceIndex > targetIndex) {
      changeImages([
        ...images.slice(0, targetIndex),
        images[sourceIndex],
        images[targetIndex],
        ...images
          .slice(targetIndex + 1)
          .filter((i) => i.imageUrl !== sourceImg),
      ]);
    } else {
      changeImages([
        ...images.slice(0, targetIndex).filter((i) => i.imageUrl !== sourceImg),
        images[targetIndex],
        images[sourceIndex],
        ...images.slice(targetIndex + 1),
      ]);
    }
  }

  function changeImages(images: ImageType[]) {
    setImages(images);
    if (props.onChangeFunction) props.onChangeFunction(images);
  }

  function handleCategory(e: any, index: number) {
    let copyImages = images;
    copyImages[index]!.category = e.target.value;
    changeImages([...copyImages]);
  }
}

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFileMutation($file: Upload!, $path: String!) {
    uploadFile(input: { file: $file, path: $path }) {
      succeeded
      fileLocation
    }
  }
`;
