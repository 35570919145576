import TagIcon from "@mui/icons-material/Tag";
import { Dispatch, SetStateAction, useState } from "react";
import {
  Chip,
} from "@mui/material";

import { VenueTag } from "../../types";
import VenueTagsTable from "./tagsTable";

export default function VenueTagsSelection(props: {
  venueTags: VenueTag[];
  setTags: Dispatch<SetStateAction<VenueTag[]>>;
  allTagsFilter?: boolean;
  setAllTagsFilter?: React.Dispatch<React.SetStateAction<boolean>>;
}) {

  return (
    <>
      {props.venueTags.map((tag: VenueTag) => (
        <Chip
          key={tag.id}
          style={{
            backgroundColor: "#c8a063",
            color: "#ffffff",
            fontSize: "18px",
            padding: "5px",
            margin: "2px",
          }}
          icon={<TagIcon style={{ color: "#fff" }} />}
          label={`${tag.name}`}
          onDelete={() =>
            props.setTags((tags) => tags.filter((t) => t.id !== tag.id))
          }
        />
      ))}
      {props.venueTags.length > 0 && <br />}
        <div style={{ marginTop: "15px", width: "100%", height: "360px" }}>
          <VenueTagsTable
            tags={props.venueTags}
            onAdd={(tag: VenueTag) =>
              !props.venueTags.find((t) => t.id === tag.id) &&
              props.setTags([...props.venueTags, tag])
            }
          />
        </div>
    </>
  );
}
