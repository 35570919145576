import { useMemo } from "react";

import { BookingSystem } from "../../../../types";
import EbicaSelect from "./EbicaSelect";
import ToretaSelect from "./ToretaSelect";
import SelectList, { SelectListProps, SelectRenderer } from "./SelectList";
import { BookingSystemSetting } from "../BasicInfo";

export default function BookingSystemSelectList({
  ebicaStoreId,
  setEbicaStoreId,
  toretaStoreId,
  setToretaStoreId,
  ...selectListProps
}: BookingSystemSelectListProps) {
  const bookingSystemRenderers = useMemo(() => {
    // setup special renderer in case the booking system requires extra input
    // e.g. Ebica requires the operator to input an ID
    return new Map<BookingSystem, SelectRenderer>([
      [
        BookingSystem.ebica,
        {
          SelectComponent: EbicaSelect,
          selectProps: { ebicaStoreId, setEbicaStoreId },
        },
      ],
      [
        BookingSystem.toreta,
        {
          SelectComponent: ToretaSelect,
          selectProps: { toretaStoreId, setToretaStoreId },
        },
      ],
    ]);
  }, [
    ebicaStoreId,
    setEbicaStoreId,
    toretaStoreId,
    setToretaStoreId,
  ]);

  return <SelectList renderers={bookingSystemRenderers} {...selectListProps} />;
}

type BookingSystemSelectListProps = {
  ebicaStoreId: null | number;
  setEbicaStoreId: (value: null | number) => void;
  toretaStoreId: null | string;
  setToretaStoreId: (value: null | string) => void;
} & SelectListProps<BookingSystem>;
